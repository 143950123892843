import React, { useState } from "react";
import { FaInfoCircle, FaPlusSquare, FaUpload } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "./TMTBUploadSection.scss";
import SelectInputComp from "../../../utils/Components/InputComps/DropdownSelectInput/SelectInputComp";

// import domain_sub_domain from "../../../data/ez_editor/domain_sub_domain.json";
// import SelectInputComp from "../../../utils/Components/InputComps/DropdownSelectInput/SelectInputComp";

const FileUploadSection = ({
  idx,
  hoverTipText,
  acceptedFiles,
  inputId,
  filesArr,
  handleFileItem,
  // handleFileDomain,
  assignmentID,
  // newTMFiles,
  // newTBFiles,
  select,
  setSelect,
  oldFiles,
  handlePastFile,
}) => {
  const options = [];
  for (const item of oldFiles) {
    const size = Number(item.file_size.split(" ")[0]) * 1000;
    options.push({
      label: item.file_name,
      value: [{ id: item.id, name: item.file_name, uploaded: true, created_at: item.created_at, size }],
    });
  }
  return (
    <>
      <div className="tmtb-upload-container-dropdown-section">
        <div className="tmtb-upload-container-dropdown-section-upload">
          <div className="tmtb-upload-container-dropdown-section-upload-label-head">
            <div className="label-with-tip">
              <div className="dropdown-label">{select && filesArr.length - 1 == idx ? "Select File" : "Upload File"}</div>
              <div
                className="show-tip-icon"
                data-tooltip-id="show-hover-tip"
                data-tooltip-content={hoverTipText}
                data-tooltip-place="top-start"
              >
                <FaInfoCircle />
              </div>
            </div>

            {select && filesArr.length - 1 == idx ? (
              <div className="editor-upload-assign-selection-container-dropdown">
                <SelectInputComp
                  className="select-container"
                  // selectedValue={oldFiles}
                  options={options}
                  handleChange={(option) => {
                    handlePastFile(option, idx);
                    setSelect(false);
                  }}
                  placeholder={"Select Files"}
                  clearInput={true}
                />
              </div>
            ) : (
              <div className="inputContainer">
                <input
                  type="file"
                  multiple={false}
                  accept={acceptedFiles}
                  value="" // this is given to clear the input value when the state gets cleared
                  style={{ display: "none", pointerEvents: assignmentID ? "auto" : "none" }}
                  onChange={(e) => {
                    handleFileItem(e, idx);
                  }}
                  id={inputId + idx}
                  disabled={!assignmentID}
                />
                <div className={`uploadBox ${!assignmentID ? "disabled" : ""}`}>
                  <div className="file_section">
                    {Object.keys(filesArr[idx]?.file)?.length === 0 ? (
                      <div className="no-file-chosen">Select file to upload</div>
                    ) : (
                      <>
                        <div className="filename">{filesArr[idx]?.file[0]?.name}</div>
                      </>
                    )}
                  </div>
                  <div className={`file_upload_button ${!assignmentID ? "disabled" : ""}`}>
                    <label htmlFor={inputId + idx}>
                      <span style={{ color: assignmentID ? "var(--prime-blue)" : "var(--light-grey)" }}>
                        <FaUpload />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="tmtb-upload-container-dropdown-section-domain">
          <SelectInputComp
            className="select-container"
            label={"Domain : Sub-domain"}
            selectedValue={filesArr[idx]?.domain}
            options={domain_sub_domain?.domain_sub_domains}
            handleChange={(option) => {
              handleFileDomain(option, idx);
            }}
            placeholder={newTMFiles?.length === 0 || newTBFiles?.length === 0 ? "Please Upload File" : "Select File Domain and Sub-Domain"}
            clearInput={true}
            disabled={!assignmentID || newTMFiles?.length === 0 || newTBFiles?.length === 0}
          />
        </div> */}
      </div>
    </>
  );
};

const TMTBUploadSection = ({
  heading,
  handleFileArr,
  filesArr,
  hoverTipText,
  acceptedFiles,
  inputId,
  handleFileItem,
  handleFileDomain,
  assignmentID,
  newTMFiles,
  newTBFiles,
  oldFiles,
  handlePastFile,
}) => {
  const [select, setSelect] = useState(false);
  return (
    <>
      <section className="tmtb-upload-section">
        <div className="tmtb-upload-container">
          <div className="tmtb-upload-container-head">
            <div className="tmtb-upload-container-head-heading">{heading}</div>

            <div className="tmtb-upload-container-head-add-btn">
              <div
                onClick={() => {
                  setSelect(!select);
                }}
                className="tmtb-upload-select"
              >
                {select ? "Upload" : "Select from past"}
              </div>
              <FaPlusSquare onClick={handleFileArr} />
            </div>
          </div>
          <div className="container-wrapper" id="tmtb-upload-container">
            {filesArr.map((item, i) => {
              return (
                <FileUploadSection
                  key={i}
                  idx={i}
                  hoverTipText={hoverTipText}
                  acceptedFiles={acceptedFiles}
                  inputId={inputId}
                  handleFileItem={handleFileItem}
                  handleFileDomain={handleFileDomain}
                  assignmentID={assignmentID}
                  filesArr={filesArr}
                  newTMFiles={newTMFiles}
                  newTBFiles={newTBFiles}
                  oldFiles={oldFiles}
                  select={select}
                  handlePastFile={handlePastFile}
                  setSelect={setSelect}
                />
              );
            })}
          </div>
        </div>
        <Tooltip id="show-hover-tip" style={{ backgroundColor: "var(--prime-blue)" }} place="top-start" />
      </section>
    </>
  );
};

export default TMTBUploadSection;
