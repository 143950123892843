// import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  postUserData,
  setFileInclude,
  setWordCountInclude,
  setSearchPhrasesInclude,
} from "../../../../store/features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import { toast } from "react-hot-toast";

import "./SearchForm.scss";
import { useRef, useState } from "react";
import CheckboxInput from "../../../../utils/Components/InputComps/CheckboxInput/CheckboxInput";
const SearchForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRef = useRef();
  const [isURLInclude, setURLInclude] = useState(null);
  const [checkType, setCheckType] = useState("entire");

  const [phraseList, setPhraseList] = useState([]);
  const { is_search_phrase_include, is_file_include, is_word_count_include } = useSelector(
    (state) => state.searchFormData.search_form_data,
  );

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-={};':"\\|.<>?~]/;
    return specialChars.test(str);
  }

  /*
    1. When User give input via form, 
       A. url   B. Check  C. checkboxes selected  D. Search Keywords 

    2. Post API hit which take all above value and give response
  */

  const handlePhrasesList = (e) => {
    const inputList = e.target.value;
    const phraseListArr = inputList.split(",");
    setPhraseList(phraseListArr);
  };

  function isValidURL(str) {
    const pattern = new RegExp(
      "^(https:\\/\\/)" + // starts with "https://"
        "([a-z\\d.-]+\\.[a-z]{2,}(?::\\d{1,5})?)" + // domain and optional port
        "(/[^?#]*?)?" + // path (optional)
        "(\\?[^#]+)?" + // query string (optional)
        "(#\\S*)?$", // fragment (optional)
      "i",
    );
    return pattern.test(str);
  }

  const onSubmitUserDetails = (e) => {
    e.preventDefault();
    let webURL = emailRef.current.value.trim(); //emailRef naming convention is not appropriate
    let isValid = isValidURL(webURL);

    if (!isValid) {
      toast.error("Please enter a valid URL");
      return;
    }

    let spclCharRes = containsSpecialChars(phraseList);
    if (spclCharRes) {
      toast.error("Speicial Character not allowed in Search Phrase Tab");
      return;
    }
    if (phraseList.length > 10) {
      toast.error("Number of Phrase should not exceed more than 10 in Search Phrase Tab");
      return;
    }

    if (phraseList.length === 0 && is_search_phrase_include) {
      toast.error("Please enter input phrase");
      return;
    }

    const user_data = {
      url: webURL,
      search_type: checkType,
      is_download_files_include: is_file_include,
      is_word_count_include: is_word_count_include,
      phrase_list: phraseList,
    };

    dispatch(postUserData(user_data))
      .then((res) => {
        if (res?.error) {
          throw res.payload;
        } else {
          let assId = res?.payload?.assignment_id;
          navigate(`/ez_search/${assId}`);
        }
      })
      .catch((err) => console.error(err?.err || err || "Error while navigation"));
  };

  const handleCheckboxChange = (newChecked, id) => {
    if (id === "search_download_file") {
      dispatch(setFileInclude(newChecked));
    }
    if (id === "word_count_webpage") {
      dispatch(setWordCountInclude(newChecked));
    }
    if (id === "search_phrase") {
      dispatch(setSearchPhrasesInclude(newChecked));
    }
  };

  return (
    <section className="search-form-main-section">
      <form className="search-form-center-box" onSubmit={onSubmitUserDetails}>
        <div className="search-form-url-section search-form-info-common-style">
          <h3 className="search-form-label-section search-checkbox-label">Enter Website URL :</h3>
          <input
            type="text"
            className="user-email-input"
            onChange={(e) => setURLInclude(e.target.value)}
            ref={emailRef}
            name="email"
            placeholder="Enter Link Here"
            id="emailField"
            autoComplete="off"
          />
        </div>
        <div className="search-form-radio-section search-form-info-common-style">
          <h3 className="search-form-label-section">Check: </h3>
          <label className="radio-control">
            <input type="radio" value="page" name="radio" onChange={(e) => setCheckType(e.target.value)} />
            <span className="radio-text">This link</span>
          </label>
          <label className="radio-control">
            <input type="radio" name="radio" value="entire" onChange={(e) => setCheckType(e.target.value)} defaultChecked={true} />
            <span className="radio-text">Entire Website</span>
          </label>
          <label className="radio-control">
            <input type="radio" name="radio" value="starting" onChange={(e) => setCheckType(e.target.value)} />
            <span className="radio-text">All links Starting with above URL</span>
          </label>
        </div>
        <div className="search-form-checkbox-section">
          <div className="checkbox-section-heading">What do you want to get done today?</div>
          <div className="checkbox-section-body-container">
            <div className="checbox-section-body-content">
              <CheckboxInput
                id="search_download_file"
                name="search_download_file"
                defaultChecked={is_file_include}
                onChange={handleCheckboxChange}
                label="Search and Download File"
              ></CheckboxInput>
              <CheckboxInput
                id="word_count_webpage"
                name="word_count_webpage"
                defaultChecked={is_word_count_include}
                onChange={handleCheckboxChange}
                label="Find Word Count of Webpages(s)"
              ></CheckboxInput>
              <div
                className={`search-form-phrase-checkbox-container ${
                  is_word_count_include ? "search-form-phrase-checkbox-active" : "search-form-phrase-checkbox-inactive"
                }`}
              >
                <CheckboxInput
                  id="search_phrase"
                  name="search_phrase"
                  defaultChecked={is_search_phrase_include}
                  onChange={handleCheckboxChange}
                  label="Search for a phrase"
                  disabled={is_word_count_include ? false : true}
                ></CheckboxInput>
                <input
                  type="text"
                  className={`user-keyword-input ${is_search_phrase_include ? "user-keyword-input-active" : "user-keyword-input-inactive"}`}
                  placeholder="Phrase 1, Phrase 2, Phrase 3"
                  onChange={handlePhrasesList}
                  disabled={is_search_phrase_include ? false : true}
                />
              </div>
            </div>
          </div>
        </div>

        <section id="search-btn">
          <button
            type="submit"
            className="tool-btn"
            disabled={(isURLInclude === null && phraseList.length === 0) || (is_file_include === false && is_word_count_include === false)}
            style={{
              backgroundColor:
                isURLInclude === null || (is_file_include === false && is_word_count_include === false) ? "#d2d2d2" : "#3cc3f2",
            }}
          >
            Search
          </button>
        </section>
      </form>
    </section>
  );
};

export default SearchForm;
