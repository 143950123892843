import { Table } from "react-bootstrap";
import "./InfoTable.scss";
import { useSelector } from "react-redux";

const InfoTable = () => {
  const data = useSelector((state) => state.ezStandaloneData.assignment_list);

  return (
    <div className="info-table-container">
      <div className="scrollable-table">
        <Table>
          <thead>
            <tr>
              <th>Assignment ID</th>
              <th>Start Time</th>
              <th>End Time</th>
            </tr>
          </thead>
          <tbody>
            {/* Conditional rendering based on data status */}
            {!data ? (
              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>
                  Please select user
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>
                  No Assignment Found!
                </td>
              </tr>
            ) : (
              data.map((row, index) => (
                <tr key={index}>
                  <td>{row.assignment_id || "-"}</td>
                  <td>{row.start_time || "-"}</td>
                  <td>{row.end_time || "-"}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default InfoTable;
