// import historyData from "../../../../data/ez_search/get_history.json";

export const initialState = {
  history_data: {
    data: null,
    loader: false,
    error: null,
  },
  curPage: null,
};
