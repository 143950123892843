import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { getAuthData } from "../../../../services/request";

import { initialState } from "./common";

// +++++++++ fetch History Data api  +++++++++++++
export const getHistoryData = createAsyncThunk("history-data/getHistoryData", async (page) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/search/?page=${page}`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      toast.error("error here ");
    }
  } catch (err) {
    console.error(`Failed to get History Data`);
    return `Failed to get History Data`;
  }
});

const fetchHistoryDataSlice = createSlice({
  name: "history-data",
  initialState,
  reducers: {
    changePage: (state, { payload }) => {
      state.curPage = payload;
    },
    resetHistoryData: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getHistoryData.pending]: (state) => {
      state.history_data.loader = true;
    },
    [getHistoryData.fulfilled]: (state, { payload }) => {
      state.history_data.loader = false;
      // console.log(payload);
      state.history_data.data = payload;
    },
    [getHistoryData.rejected]: (state, { payload }) => {
      state.history_data.loader = false;
      state.history_data.error = payload;
    },
  },
});

export const { changePage, resetHistoryData } = fetchHistoryDataSlice.actions;

export default fetchHistoryDataSlice.reducer;
