import React, { useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import "./SlidePageLevelInformation.scss";
import slide_level from "./../../../data/ez_editor_tracker/slide_level.json";
import { Table } from "react-bootstrap";

const SlidePageLevelInformation = () => {
  const [showSlidePageInfo, setShowSlidePageInfo] = useState(false);
  const [showHeading, setShowHeading] = useState(true);
  const slidePageLevelInformation = useSelector((state) => state.editorTrackerData.slide.slidePageLevelInformation);
  const loading = useSelector((state) => state.editorTrackerData.loading);

  const toggleSlideInfo = () => {
    setShowSlidePageInfo(!showSlidePageInfo);
    setShowHeading(false);
  };

  const goBack = () => {
    setShowSlidePageInfo(false);
    setShowHeading(true);
  };

  return (
    <div className="slide_level_table_data_container">
      {showHeading && (
        <h2 className="slide_page_level_heading" onClick={toggleSlideInfo}>
          Click here to See Slide Page Level Information
        </h2>
      )}
      {showSlidePageInfo && (
        <div className={`slide-wrapper ${showSlidePageInfo ? "open" : ""}`}>
          <div className="new_slide_level_heading_alignment">
            <div className="slide_page_level_heading">Slide Page Level Information</div>
            <span onClick={goBack}>
              <RiArrowGoBackLine className="go_back_icon" />
            </span>
          </div>
          {!loading && (
            <div className="slide_level_table_scrollable">
              <Table className="slide_page_level_information_table">
                <thead className="slide_page_level_cols_data">
                  <tr>
                    <th>Editor ID</th>
                    <th>Part ID</th>
                    <th>TR Status</th>
                    <th>PR Status</th>
                    <th>QA Status</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody className="slide_level_table_body">
                  {slidePageLevelInformation.length > 0
                    ? slidePageLevelInformation.map((row, index) => (
                        <tr key={index}>
                          <td>{row.editor_id || "-"}</td>
                          <td>{row.part_id || "-"}</td>
                          <td>{row.translation_status || "-"}</td>
                          <td>{row.proofreader_status || "-"}</td>
                          <td>{row.quality_status || "-"}</td>
                          <td>{row.comments && row.comments.length > 0 ? row.comments.join(", ") : "-"}</td>
                        </tr>
                      ))
                    : slide_level.map((row, index) => (
                        <tr key={index}>
                          <td>{row.editor_id || "-"}</td>
                          <td>{row.part_id || "-"}</td>
                          <td>{row.translation_status || "-"}</td>
                          <td>{row.proofreader_status || "-"}</td>
                          <td>{row.quality_status || "-"}</td>
                          <td>{row.comments && row.comments.length > 0 ? row.comments.join(", ") : "-"}</td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SlidePageLevelInformation;
