import { toast } from "react-hot-toast";

const TMTB_File_Upload_Size_Hundred_MB = 5;
const fileSizeFactor = TMTB_File_Upload_Size_Hundred_MB || 1;

export function filterFilesByExtension(fileData, acceptedFormats) {
  return fileData.filter((file) => {
    const extension = file.name.split(".").pop();
    return acceptedFormats.includes(extension.toLowerCase());
  });
}

export function filterFilesByExistence(fileData, existingFiles) {
  const files = existingFiles.map((file) => {
    if (typeof file === "object") return file.file_name;
    else if (typeof file === "string") return file;
  });
  return fileData.filter((file) => {
    if (files.includes(file.name)) {
      toast.error(`${file.name} already exists. Please choose another file.`);
    } else {
      return file;
    }
  });
}

export function handleExistingFileCheck(event, acceptedFormats, existingFiles) {
  const filesData = Array.from(event.target.files);

  // Filtering for extension
  const filesFilterForExtension = filterFilesByExtension(filesData, acceptedFormats);

  // Filtering for size (assuming termBaseFileFilter is defined)
  // const filesFilteredForSize = termBaseFileFilter(filesFilterForExtension);

  // Filtering for file existence
  const filteredForExistence = filterFilesByExistence(filesFilterForExtension, existingFiles);
  return filteredForExistence;
}

export function termBaseFileFilter(files) {
  // const acceptedFormats = ["csv", "tmx", "mxliff", "xliff", "mqxlz"];
  // let filteredFiles = files.filter((file) => {
  //   const extension = file.name.split(".").pop();
  //   return acceptedFormats.includes(extension.toLowerCase());
  // });
  let filteredFiles = files;

  // const oversizedFiles = filteredFiles.filter((file) => file.size > 100000000);
  const oversizedFiles = filteredFiles.filter((file) => file.size > fileSizeFactor * 100000000);
  if (oversizedFiles.length > 0) {
    const removedFileNames = [];
    for (let i = 0; i < oversizedFiles.length; i++) {
      const file = oversizedFiles[i];
      removedFileNames.push(file.name);
    }
    filteredFiles = filteredFiles.filter((file) => !oversizedFiles.includes(file));
    toast.error(
      `${removedFileNames.join(
        ", ",
        // )},  are removed as the file exceeded the maximum size limit of 500MB`,
      )},  are removed as the file exceeded the maximum size limit of ${fileSizeFactor}00 MB`,
    );
  }
  return filteredFiles;
}
