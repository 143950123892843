import "./SearchWordCountTab.scss";
import { FaFilter } from "react-icons/fa";
import WordCountFilter from "../WordCountFilter/WordCountFilter";
import { handleWordCountFilterModal } from "../../../../../store/features/ezSearchSlices/filterDataSlice/filterDataSlice";
import ContentShortenComp from "../../../../../utils/functions/ContentShortenComp";

import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_STATUS, ACTIVE_TAB } from "../../../../../utils/namespaces/ACTIVE_TAB";
import LoaderModal from "../../../../../utils/Components/LoaderModal/LoaderModal";
import { useEffect, useState } from "react";
import { changeWCPage, getWordCountData } from "../../../../../store/features/ezSearchSlices/wordCountDataSlice/wordCountDataSlice";
import WordCountPagination from "../WordCountPagination/WordCountPagination";
import { updatePhraseData } from "../../../../../store/features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import { toast } from "react-hot-toast";
// import { useNavigate } from "react-router-dom";

const SearchWordCountFileInfo = () => {
  const { word_count_data } = useSelector((state) => state.wordCountData);

  return (
    <section className="word-count-list">
      {word_count_data?.data?.data?.web_data.map((curVal, id) => {
        return (
          <div className="word-count-list-item" key={id}>
            <div className="link-item">
              <a href={curVal.web_url} target="_blank" rel="noreferrer">
                <ContentShortenComp content={curVal.web_url} max_length={70} last_chars_length={3} />
              </a>
            </div>
            <div className="word-count-item text-data-left">{curVal.word_count ? curVal.word_count.toLocaleString() : "0"}</div>
            <div className="match-phrases-item text-data-left">
              {curVal.match_phrase.filter((value, index, arr) => arr.indexOf(value === index)).toString() || "null"}
            </div>
          </div>
        );
      })}
    </section>
  );
};

//this component should be in different file

const SearchWordCountInfo = () => {
  const dispatch = useDispatch();
  const { show_wc_filter_modal } = useSelector((state) => state.filterData);
  const { word_count_data, curPage } = useSelector((state) => state.wordCountData);
  const { active_tab, search_form_data, fetch_status } = useSelector((state) => state.searchFormData);
  const [upgradePhrase, setUpgradePhrase] = useState(search_form_data?.phrase_list || []);
  const [searchLink, setSearchLink] = useState(null);

  function handleChange(e) {
    const inputList = e.target.value;
    let inputListArr = inputList.split(",");
    // inputListArr.push("#null#");
    setUpgradePhrase(inputListArr);
  }

  function handleUpdatePhraseLink() {
    dispatch(handleWordCountFilterModal(false));
    dispatch(changeWCPage(1));

    let searchLinkList;
    if (searchLink) {
      searchLinkList = [];
      searchLinkList.push(searchLink);
    }
    if (upgradePhrase.length > 10) {
      toast.error("Number of Phrase should not exceed more than 10 in Search Phrase Tab");
      return;
    }
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curPage,
      search_phrase_data: upgradePhrase,
      search_link_data: searchLinkList,
    };

    let update_list = {
      assId: localStorage.getItem("assignment_id"),
      search_phrase_list: upgradePhrase,
    };

    if (fetch_status.update_status !== "expire") {
      dispatch(updatePhraseData(update_list))
        .then((res) => {
          if (res?.error) {
            throw res.payload;
          } else {
            dispatch(getWordCountData(data));
          }
        })
        .catch((err) => console.error(err?.err || err || "Error while Updating Data"));
    } else {
      dispatch(getWordCountData(data));
    }
  }

  useEffect(() => {
    if (upgradePhrase && search_form_data.is_search_phrase_include) {
      let inputSearchBox = document.getElementById("phrase-info");

      let newUpgradePhraseList = [...upgradePhrase];
      inputSearchBox.value = newUpgradePhraseList.filter((curr) => curr !== "#null#");
    }
  }, [upgradePhrase]);

  useEffect(() => {
    if (search_form_data.phrase_list) {
      let inputSearchBox = document.getElementById("phrase-info");
      let inputSearchBoxList = [...search_form_data.phrase_list];
      inputSearchBox.value = inputSearchBoxList.filter((curr) => curr !== "#null#");
    }
  }, [search_form_data.phrase_list]);

  // useEffect(() => {
  //   if (fetch_status.update_status && search_form_data.is_search_phrase_include) {
  //     if (fetch_status.update_status === "expire") {
  //       document.getElementById("#phrase-info").disabled = true;
  //     } else {
  //       document.getElementById("#phrase-info").disabled = false;
  //     }
  //   }
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("phrase-info").disabled = true;
    }, 60 * 60 * 1000);
  }, []);

  return (
    <section className={active_tab === ACTIVE_TAB.WORD_COUNT_TAB ? "active-tab" : "search-word-count-tab-section"}>
      <div className="search-phrase-input-box">
        <div className="result-info-title">Search Phrase : </div>
        <input
          type="text"
          id="phrase-info"
          className="result-info-inputbox"
          autoComplete="off"
          onChange={(e) => handleChange(e)}
          disabled={fetch_status?.update_status === "expire"}
        />
        <button className="spcl-btn" onClick={handleUpdatePhraseLink}>
          Search in URLs(s)
        </button>
      </div>
      <div className="search-link-input-box">
        <div className="result-info-title">Search Link : </div>
        <input type="text" className="result-info-inputbox" onChange={(e) => setSearchLink(e.target.value)} />
        <button className="spcl-btn" onClick={handleUpdatePhraseLink}>
          Search in URLs(s)
        </button>
      </div>
      <section className="word-count-result-section">
        <div className="word-count-titles">
          <div>Links</div>
          <div className=" text-data-left">Word Count</div>
          <div className=" text-data-left">
            Match Phrases
            <button
              className="filter-icon-btn"
              onClick={() => dispatch(handleWordCountFilterModal(true))}
              disabled={search_form_data.phrase_list.length > 0 ? false : true}
            >
              <FaFilter />
            </button>
          </div>
        </div>

        {show_wc_filter_modal && <WordCountFilter />}
        {word_count_data?.loader === true ? <LoaderModal width={"85vw"} verticleHeight={"43vh"} /> : <SearchWordCountFileInfo />}
      </section>
      {!word_count_data?.loader && <WordCountPagination />}
    </section>
  );
};

const SearchWordCountTab = () => {
  const dispatch = useDispatch();
  const { fetch_status } = useSelector((state) => state.searchFormData);
  const { curPage, search_phrase_data } = useSelector((state) => state.wordCountData);
  const { active_tab } = useSelector((state) => state.searchFormData);
  // console.log(fetch_status);
  // const navigate = useNavigate();

  useEffect(() => {
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curPage,
      search_phrase_data: search_phrase_data,
      search_link_data: [],
    };
    if (fetch_status?.search_status === ACTIVE_STATUS.SEARCH_COMPLETED || fetch_status?.search_status === ACTIVE_STATUS.SEARCH_PARTIAL) {
      if (active_tab === ACTIVE_TAB.WORD_COUNT_TAB) {
        dispatch(getWordCountData(data));
      }
    }
  }, [fetch_status?.search_status, active_tab]);

  useEffect(() => {
    dispatch(changeWCPage(1)); //instead of dispatching WCpage 1 while rendering first time we can set initial state as 1 in redux
  }, []);

  useEffect(() => {
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curPage,
      search_phrase_data: search_phrase_data,
      search_link_data: [], //this should not be empty
    };

    // if (curPage >= 1 && fetch_status.search_status === "search_completed") dispatch(getWordCountData(data));

    if (
      (curPage >= 1 && fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED) ||
      (curPage >= 1 && fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL)
    ) {
      dispatch(getWordCountData(data));
    }
  }, [curPage]);

  // useEffect(() => {
  //   if (!word_count_data.data) {
  //     navigate("/ez_search");
  //   }
  // }, []);

  return (
    <section className="search-word-count-section">
      {fetch_status.search_status === ACTIVE_STATUS.SEARCH_STARTED && <LoaderModal width={"85vw"} verticleHeight={"60vh"} />}
      {fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED && <SearchWordCountInfo />}
      {fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL && <SearchWordCountInfo />}
    </section>
  );
};

export default SearchWordCountTab;
