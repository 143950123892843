// ========================== SLICE ====================== //

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getAuthData, postAuthData } from "../../../services/request";
import { createOptionsArr, filesObj, initialState } from "./common";
import { getWorkspaceUserOptions, resetEditorUserTMData } from "../ezEditorUserTM/ezEditorUserTMSlice";

export const getWorkspaceAssgnOptions = createAsyncThunk("editor-tm-data/getWorkspaceAssgnOptions", async ({ rejectWithValue }) => {
  let url = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/tools/assignment`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return createOptionsArr(res.data);
    } else {
      return rejectWithValue(res.message);
    }
  } catch (err) {
    return err;
  }
});

export const getAssignmentInfo = createAsyncThunk("editor-tm-data/getAssignmentInfo", async ({ assgnID }, { rejectWithValue }) => {
  let url = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/tools/assignment/${assgnID}`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue(res.message);
    }
  } catch (err) {
    return err;
  }
});

export const getPastTM = createAsyncThunk("editor-tm-data/getPastTM", async ({ assgnID }, { rejectWithValue }) => {
  let url = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/tools/pastTM?assignment_id=${assgnID}`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res.data;
    } else {
      return rejectWithValue(res.message);
    }
  } catch (err) {
    ("1.42 KB");
    return err;
  }
});
export async function multiPartUploadFunction(file, type, key) {
  if (!file[0]) return;
  const uploadURL = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/file/get-urls`;
  const chunkSize = 10; // MB
  const assignmentId = localStorage.getItem("assignment_id");

  let fileDataInfo = {
    file: file[0],
    uploadingChunk: 0,
    totalChunks: 0,
    uploadId: null,
    chunkUploadUrls: [],
    chunksPartUploadInfo: [],
    assignmentId: assignmentId,
    s3FilePath: null,
    individualFileId: null,
    fileType: type,
  };

  const fileData = fileDataInfo;

  try {
    // Prepare data for fetching chunk URLs
    let data = {
      file_type: fileData.fileType,
      file_name: fileData.file.name,
      file_size: fileData.file.size,
      chunk_size: chunkSize, // Convert MB to bytes
    };

    if (key == "assignment_id") {
      data.assignment_id = fileData.assignmentId;
    }

    const response = await postAuthData(uploadURL, data);
    if (!response.success) {
      throw new Error(response.message || "Something went wrong while uploading the file");
    }

    let responseData = {
      uploadId: response?.data?.upload_id,
      urls: response?.data?.part_urls,
      s3Path: response?.data?.file_path,
      individualFileId: response?.data?.file_id,
      fileType: fileData.fileType,
    };

    if (response?.data?.file_id) {
      return { file_id: response?.data?.file_id };
    } else {
      const chunkSizeBytes = chunkSize * 1024 * 1024;
      const totalChunks = Math.ceil(fileData.file.size / chunkSizeBytes);
      const uploadChunkTracker = [];

      // Function to upload an individual chunk
      const uploadChunk = async (chunkIdx, chunkSizeBytes) => {
        const sendAxios = axios.create();
        const initialPointer = chunkIdx * chunkSizeBytes;
        const chunk = fileData.file.slice(initialPointer, initialPointer + chunkSizeBytes, fileData.file.type);

        return sendAxios.put(responseData.urls[chunkIdx], chunk, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      };

      try {
        for (let chunk = 0; chunk < totalChunks; chunk++) {
          const res = await uploadChunk(chunk, chunkSizeBytes);
          let partData = {
            ETag: JSON.parse(res.headers.etag),
            PartNumber: chunk + 1,
          };
          uploadChunkTracker.push(partData);
        }

        const combinationData = {
          part_list: uploadChunkTracker,
          upload_id: responseData.uploadId,
          file_path: responseData.s3Path,
        };
        const combinationUrl = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/file/multipart-complete`;
        const combinationResponse = await postAuthData(combinationUrl, combinationData);

        if (!combinationResponse.success) {
          toast.error(`${file.name} upload failed`);
          // throw new Error(combinationResponse.message || "File Upload Failed");
        }

        return { file_path: responseData.s3Path };
      } catch (error) {
        console.error("Error during chunk upload:", error);
        throw new Error("File can't be uploaded");
      }
    }
  } catch (error) {
    console.error("Error during multi-part upload:", error);
    throw new Error("Something went wrong while uploading the file");
  }
}

export const createPostTermBaseUploadData = createAsyncThunk("editor-tm-data/createPostTermBaseUploadData", async (args, thunkAPI) => {
  const {
    editorData: { user_tm_data },
  } = thunkAPI.getState();
  // filtering past files from user input not to upload again
  const TMFiles = user_tm_data.tm_data.files_data.files.filter((item) => item.file[0]?.uploaded != true);
  const TBFiles = user_tm_data.tb_data.files_data.files.filter((item) => item.file[0]?.uploaded != true);
  // const clientOfClientDetails = user_tm_data.upload_data.client_details_text;

  const tmPromiseArray = TMFiles?.map(async (file, idx) => {
    try {
      const res = await multiPartUploadFunction(file.file, "TM", args.key);
      if (res.file_id) {
        thunkAPI.dispatch(updateTMFileId({ idx, file_id: res.file_id }));
      } else if (res.file_path) {
        thunkAPI.dispatch(updateTMS3Path({ idx, file_path: res.file_path }));
      }
    } catch (error) {
      // Handle the error (e.g., log or show a message)
      // console.error("Error in TM multiPartUploadFunction:", error);
      return;
    }
  });
  const tbPromiseArray = TBFiles?.map(async (file, idx) => {
    try {
      const res = await multiPartUploadFunction(file.file, "TB", args.key);
      if (res.file_id) {
        thunkAPI.dispatch(updateTBFileId({ idx, file_id: res.file_id }));
      } else if (res.file_path) {
        thunkAPI.dispatch(updateTBS3Path({ idx, file_path: res.file_path }));
      }
    } catch (error) {
      // console.error("Error in TM multiPartUploadFunction:", error);
      return;
      // Handle the error (e.g., log or show a message)
    }
  });
  await Promise.all([...tmPromiseArray, ...tbPromiseArray]);

  const tm_files = thunkAPI.getState().editorData.user_tm_data.tm_data.files_data.files;
  const tb_files = thunkAPI.getState().editorData.user_tm_data.tb_data.files_data.files;

  const createPayload = (TMFilesArr, TBFilesArr) => {
    const tm_file_ids = [];
    const tm_file_path_list = [];
    const tb_file_ids = [];
    const tb_file_path_list = [];
    const previous_tm_user_tm_ids = [];

    if (TMFilesArr) {
      TMFilesArr.forEach((file) => {
        if (file && typeof file === "object") {
          if (file.file[0]?.uploaded) {
            previous_tm_user_tm_ids.push(file.file[0].id);
          } else {
            const { file_id, file_path } = file;
            // const domain = file?.domain?.value;

            if (file_id !== null) {
              tm_file_ids.push(file_id);
            }

            if (file_path !== null) {
              tm_file_path_list.push(file_path);
            }
          }
        }
      });
    }
    if (TBFilesArr) {
      TBFilesArr.forEach((file) => {
        if (file && typeof file === "object") {
          if (file.file[0]?.uploaded) {
            previous_tm_user_tm_ids.push(file.file[0].id);
          } else {
            const { file_id, file_path } = file;
            // const domain = file?.domain?.value;

            if (file_id !== null) {
              tm_file_ids.push(file_id);
            }

            if (file_path !== null) {
              tm_file_path_list.push(file_path);
            }
          }
        }
      });
    }

    const payload = {
      tm_file_data: {
        tm_file_ids: tm_file_ids,
        file_path_list: tm_file_path_list,
      },
      tb_file_data: {
        tm_file_ids: tb_file_ids,
        file_path_list: tb_file_path_list,
      },
    };

    if (args.key == "assignment_id") {
      payload.assignment_id = localStorage.getItem("assignment_id");
      payload.previous_tm_user_tm_ids = previous_tm_user_tm_ids;
    } else if (args.key == "resource_id") {
      payload.resource_id = localStorage.getItem("resource_id");
    }

    return payload;
  };

  const payload = createPayload(tm_files, tb_files);
  return payload;
});

export const postTermBaseFileUpload = createAsyncThunk("editor-tm-data/postTermBaseFileUpload", async (args, thunkAPI) => {
  const url = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/tools/translation/${args.type}`;

  const data = thunkAPI.getState().editorData.user_tm_data.upload_data.file_data;

  try {
    const res = await postAuthData(url, data);
    if (res.success) {
      toast.success("The translation resources have been uploaded successfully.", {
        id: "upload-file",
      });
      thunkAPI.dispatch(resetEditorData());
      thunkAPI.dispatch(resetEditorUserTMData());
      if (args.type == "client") {
        thunkAPI.dispatch(getWorkspaceAssgnOptions({}));
      } else {
        thunkAPI.dispatch(getWorkspaceUserOptions({}));
      }

      return res;
    } else {
      throw new Error(res.message || "Something went wrong while uploading files");
    }
  } catch (error) {
    toast.error("Something went wrong while uploading files");
    thunkAPI.dispatch(resetEditorData());
    thunkAPI.dispatch(resetEditorUserTMData());
    if (args.type == "client") {
      thunkAPI.dispatch(getWorkspaceAssgnOptions({}));
    } else {
      thunkAPI.dispatch(getWorkspaceUserOptions({}));
    }
  }
});

const editorDataSlice = createSlice({
  name: "editor-tm-data",
  initialState,
  reducers: {
    addWorkspaceAssgnOption: (state, { payload }) => {
      state.user_tm_data.assignment_id = payload;
      localStorage.setItem("assignment_id", payload.value);
    },
    addDomainSubDomain: (state, { payload }) => {
      state.user_tm_data.upload_data.domain = payload;
    },
    addTmFiles: (state, { payload }) => {
      state.user_tm_data.tm_data.files_data.files = [...state.user_tm_data.tm_data.files_data.files, ...payload];
    },
    addClientDetailsText: (state, { payload }) => {
      state.user_tm_data.upload_data.client_details_text = payload;
    },
    addTMFilesArrItem: (state) => {
      // this func is created to give filesObj obj an id corresponding to the idx for info fetching of each TM file obj created
      function addFilesArrItemID(existingArray, itemToAdd) {
        const newArray = [...existingArray];

        const lastId = newArray.length > 0 ? newArray[newArray.length - 1].id : -1;

        const newItem = {
          ...itemToAdd,
          id: lastId + 1,
        };

        newArray.push(newItem);
        state.user_tm_data.tm_data.files_data.files = [...newArray];
      }
      addFilesArrItemID(state.user_tm_data.tm_data.files_data.files, filesObj);
    },
    addTBFilesArrItem: (state) => {
      // this func is created to give filesObj obj an id corresponding to the idx for info fetching of each TB file obj created
      function addFilesArrItemID(existingArray, itemToAdd) {
        const newArray = [...existingArray];

        const lastId = newArray.length > 0 ? newArray[newArray.length - 1].id : -1;

        const newItem = {
          ...itemToAdd,
          id: lastId + 1,
        };

        newArray.push(newItem);
        state.user_tm_data.tb_data.files_data.files = [...newArray];
      }
      addFilesArrItemID(state.user_tm_data.tb_data.files_data.files, filesObj);
    },
    addTMFilesArrFile: (state, { payload }) => {
      // required input format==> {item_id: 0, value:  fileobj }
      state.user_tm_data.tm_data.files_data.files[payload.item_id].file = payload.value;
      // state.user_tm_data.tm_data.files_data.tm_files = [...state.user_tm_data.tm_data.files_data.tm_files, ...payload.value];
      state.user_tm_data.tm_data.files_data.new_tm_files = state.user_tm_data.tm_data.files_data.files.map((item) => item.file[0]);
    },
    addTBFilesArrFile: (state, { payload }) => {
      // required input format==> {item_id: 0, value: fileobj }
      state.user_tm_data.tb_data.files_data.files[payload.item_id].file = payload.value;
      // state.user_tm_data.tb_data.files_data.tb_files = [...state.user_tm_data.tb_data.files_data.tb_files, ...payload.value];
      state.user_tm_data.tb_data.files_data.new_tb_files = state.user_tm_data.tb_data.files_data.files.map((item) => item.file[0]);
    },
    addTMFilesArrDomain: (state, { payload }) => {
      // required input fromat ==> {item_id: 0, value: fileobj }
      state.user_tm_data.tm_data.files_data.files[payload.item_id].domain = payload.value;
    },
    addTBFilesArrDomain: (state, { payload }) => {
      // required input fromat ==> {item_id: 0, value: fileobj }
      state.user_tm_data.tb_data.files_data.files[payload.item_id].domain = payload.value;
    },
    updateTMFileId: (state, { payload }) => {
      const { idx, file_id } = payload;
      const File = { ...state };
      File.user_tm_data.tm_data.files_data.files[idx] = { ...File.user_tm_data.tm_data.files_data.files[idx], file_id };
      state = { ...File };
    },
    updateTMS3Path: (state, action) => {
      const { idx, file_path } = action.payload;
      const file = state.user_tm_data.tm_data.files_data.files[idx];
      if (file) {
        file.file_path = file_path;
      }
    },
    updateTBFileId: (state, { payload }) => {
      const { idx, file_id } = payload;
      const File = { ...state };
      File.user_tm_data.tb_data.files_data.files[idx] = { ...File.user_tm_data.tb_data.files_data.files[idx], file_id };
      state = { ...File };
    },
    updateTBS3Path: (state, action) => {
      const { idx, file_path } = action.payload;
      const file = state.user_tm_data.tb_data.files_data.files[idx];
      if (file) {
        file.file_path = file_path;
      }
    },
    resetEditorData: (state) => {
      state.user_tm_data = initialState.user_tm_data;
    },
    // removeTmFile: (state, { payload }) => {
    //   console.log(payload);
    //   state.user_tm_data.tm_data.files_data.files = state.user_tm_data.tm_data.files_data.files.filter((file, i) => i !== payload);
    // },
  },
  extraReducers: {
    // Fetching workspace assignment Options //
    [getWorkspaceAssgnOptions.pending]: (state) => {
      state.user_tm_data.workspace_assgn_list.loading = true;
    },
    [getWorkspaceAssgnOptions.fulfilled]: (state, { payload }) => {
      state.user_tm_data.workspace_assgn_list.loading = false;
      state.user_tm_data.workspace_assgn_list.data = payload;
    },
    [getWorkspaceAssgnOptions.rejected]: (state, action) => {
      state.user_tm_data.workspace_assgn_list.loading = false;
      state.user_tm_data.workspace_assgn_list.error = action.message;
    },
    // Fetching workspace assignment info //
    [getAssignmentInfo.pending]: (state) => {
      state.user_tm_data.assignment_info.loading = true;
    },
    [getAssignmentInfo.fulfilled]: (state, { payload }) => {
      state.user_tm_data.assignment_info.loading = false;
      state.user_tm_data.assignment_info.data.assignment_id = payload.assignment_id;
      state.user_tm_data.assignment_info.data.charge_code = payload.charge_code;
      state.user_tm_data.assignment_info.data.word_count = payload.client_wc;
      state.user_tm_data.assignment_info.data.committed_delivery = payload.committed_time;
      state.user_tm_data.assignment_info.data.entity_code = payload.entity_code;
      state.user_tm_data.assignment_info.data.file_name = payload.file_name;
      state.user_tm_data.assignment_info.data.file_ext = payload.file_type;
      state.user_tm_data.assignment_info.data.language_pair.source_language = payload.lang_pair.source_lang;
      state.user_tm_data.assignment_info.data.language_pair.target_language = payload.lang_pair.target_lang;
      state.user_tm_data.assignment_info.data.requester_name = payload.requester_name;
      state.user_tm_data.tm_data.files_data.old_tm_files = payload?.existing_files?.tm_files;
      state.user_tm_data.tb_data.files_data.old_tb_files = payload?.existing_files?.tb_files;
    },
    [getAssignmentInfo.rejected]: (state, action) => {
      state.user_tm_data.assignment_info.loading = false;
      state.user_tm_data.workspace_assgn_list.error = action.message;
    },
    //
    [getPastTM.fulfilled]: (state, { payload }) => {
      state.user_tm_data.past_tm = payload;
    },
    // creation of upload post data //
    [createPostTermBaseUploadData.pending]: (state) => {
      state.user_tm_data.loading = true;
    },
    [createPostTermBaseUploadData.fulfilled]: (state, { payload }) => {
      state.user_tm_data.upload_data.file_data = payload;
      state.user_tm_data.loading = false;
    },
    [createPostTermBaseUploadData.rejected]: (state) => {
      state.user_tm_data.loading = false;
    },
    [postTermBaseFileUpload.pending]: (state) => {
      state.user_tm_data.loading = true;
    },
    [postTermBaseFileUpload.fulfilled]: (state) => {
      // state.user_tm_data.upload_data.res_info = payload;
      state.user_tm_data.loading = false;
      // state.user_tm_data.upload_data.res_info = null;
    },
    [postTermBaseFileUpload.rejected]: (state) => {
      state.user_tm_data.loading = false;
    },
  },
});

export const {
  addWorkspaceAssgnOption,
  addDomainSubDomain,
  addTmFiles,
  removeTmFile,
  addClientDetailsText,
  addTMFilesArrItem,
  addTBFilesArrItem,
  addTMFilesArrFile,
  addTBFilesArrFile,
  addTMFilesArrDomain,
  addTBFilesArrDomain,
  updateTMFileId,
  updateTMS3Path,
  updateTBFileId,
  updateTBS3Path,
  resetEditorData,
} = editorDataSlice.actions;

export default editorDataSlice.reducer;
