import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "./common";
import { getAuthData } from "../../../services/request";

export const getStandaloneUserOptions = createAsyncThunk("editor-tm-data/getStandaloneUserOptions", async ({ rejectWithValue }) => {
  let url = `${process.env.REACT_APP_PUBLISH_API_URL}/api/publish-allocate/admin/v1/standalone/user-list`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      const options = res.data.map((cur) => {
        return { label: cur.email_id, value: cur.email_id };
      });
      return options;
    } else {
      return rejectWithValue(res.message);
    }
  } catch (err) {
    return err;
  }
});

export const getStandaloneAssignmentInfo = createAsyncThunk("editor-tm-data/getStandaloneAssignmentInfo", async (user_email, thunkAPI) => {
  const url = new URL(`${process.env.REACT_APP_PUBLISH_API_URL}/api/publish-allocate/admin/v1/standalone/assignment-info`);
  url.searchParams.append("user_email", user_email);

  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res.data;
    } else {
      return thunkAPI.rejectWithValue(res.message);
    }
  } catch (err) {
    return err;
  }
});

const ezStandaloneDataSlice = createSlice({
  name: "ezStandaloneData",
  initialState,
  reducers: {
    resetEzStandalone: (state) => {
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.user_list = initialState.user_list;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStandaloneUserOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStandaloneUserOptions.fulfilled, (state, { payload }) => {
        state.user_list = payload;
      })
      .addCase(getStandaloneAssignmentInfo.fulfilled, (state, { payload }) => {
        state.assignment_list = payload;
      });
  },
});

export const { resetEzStandalone } = ezStandaloneDataSlice.actions;

export default ezStandaloneDataSlice.reducer;
