export const createOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: `${cur.assignment_id} - ${cur.requester_name}`, value: cur.assignment_id };
  });
  return options_format_data;
};

export const filesObj = {
  file: {},
  id: 0,
  file_id: null,
  domain: null,
  file_path: null,
};

export const initialState = {
  user_tm_data: {
    assignment_id: null,
    workspace_assgn_list: {
      data: [],
      loading: null,
      error: null,
    },
    assignment_info: {
      data: {
        assignment_id: null,
        language_pair: {
          source_language: null,
          target_language: null,
        },
        word_count: null,
        requester_name: null,
        charge_code: null,
        file_name: null,
        file_ext: null,
        entity_code: null,
        committed_delivery: null,
      },
      loading: null,
      error: null,
    },
    upload_data: {
      file_data: null,
      domain: null,
      client_details_text: null,
      res_info: null,
    },
    tm_data: {
      files_data: {
        files: [filesObj],
        files_name: {
          existing_files: [],
        },
        new_tm_files: [],
        old_tm_files: [],
      },
      loading: null,
      error: null,
    },
    tb_data: {
      files_data: {
        files: [filesObj],
        files_name: {
          existing_files: [],
        },
        new_tb_files: [],
        old_tb_files: [],
      },
      loading: null,
      error: null,
    },
    past_tm: { tm: [], tb: [] },
    loading: null,
    error: null,
    success: null,
  },
};
