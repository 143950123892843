export const initialState = {
  search_form_data: {
    assignment_id: null,
    url: null,
    search_type: "entire",
    is_download_files_include: false,
    is_word_count_include: false,
    is_search_phrase_include: false,
    is_file_include: false,
    phrase_list: [],
    loader: null,
    error: null,
  },

  fetch_status: {
    search_status: null,
    update_status: null,
    loader: null,
    error: null,
  },
  // updatePhraseList: [],
  active_tab: null,
  active_menu: null,
  loader: null,
  error: null,
  search_form_page_access: false,
};
