// ========================== SLICE ====================== //

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthData } from "../../../services/request";
import { createOptionsArr, initialState } from "./common";

export const getWorkspaceUserOptions = createAsyncThunk("editor-user-tm-data/getWorkspaceUserOptions", async ({ rejectWithValue }) => {
  let url = `${process.env.REACT_APP_USER_TM_API_URL}/user-tm-manager/api/v1/tools/resources`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return createOptionsArr(res.data);
    } else {
      return rejectWithValue(res.message);
    }
  } catch (err) {
    return err;
  }
});

const editorUserTMDataSlice = createSlice({
  name: "editor-user-tm-data",
  initialState,
  reducers: {
    addWorkspaceResourceOption: (state, { payload }) => {
      state.user_data.resource_id = payload;
      localStorage.setItem("resource_id", payload.value);
    },
    resetEditorUserTMData: (state) => {
      state.user_data = initialState.user_data;
    },
  },
  extraReducers: {
    // Fetching workspace assignment Options //
    [getWorkspaceUserOptions.pending]: (state) => {
      state.user_data.workspace_user_list.loading = true;
    },
    [getWorkspaceUserOptions.fulfilled]: (state, { payload }) => {
      state.user_data.workspace_user_list.loading = false;
      state.user_data.workspace_user_list.data = payload;
    },
    [getWorkspaceUserOptions.rejected]: (state, action) => {
      state.user_data.workspace_user_list.loading = false;
      state.user_data.workspace_user_list.error = action.message;
    },
    // Fetching workspace assignment info //
    //
  },
});

export const { addWorkspaceResourceOption, resetEditorUserTMData } = editorUserTMDataSlice.actions;

export default editorUserTMDataSlice.reducer;
