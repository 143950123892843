import React from "react";
import Navbar from "../features/Navbar/Navbar";

const BodyLayout = () => {
  return (
    <div className="navbar-container">
      <Navbar />
    </div>
  );
};

export default BodyLayout;
