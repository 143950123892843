// ========================== SLICE ======================

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getAuthData, postAuthData } from "../../../services/request";
import { initialState } from "./common";

export const getFlipHistoryData = createAsyncThunk("flip-data/getFlipHistoryData", async (args, thunkAPI) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezflip/v1/flip/?page=${args}`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      thunkAPI.dispatch(setFlipTotalPage(res.total_page));
      return res.data;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      toast.error("error in getting FlipHistory Data ");
    }
  } catch (err) {
    toast.error(`Failed to get flip History Data`);
  }
});

export const statRept = createAsyncThunk("flip-data/statRept", async (_, thunkAPI) => {
  try {
    var intvId = setInterval(() => {
      getAuthData(`${process.env.REACT_APP_API_URL}/api/ezflip/v1/flip/${localStorage.getItem("assignment_id")}`).then((res) => {
        if (res.success) {
          thunkAPI.dispatch(setFlipStatus(res.status));
          if (res.status === "Done" || res.status === "Error") {
            thunkAPI.dispatch(setDwnldLink(res?.download_link));
            thunkAPI.dispatch(setFileText(null));
            clearInterval(intvId);
          }
        }
      });
    }, 10 * 1000);
  } catch (error) {
    toast.error(error);
  }
});

export const statusAPI = createAsyncThunk("flip-data/statusAPI", async (_, thunkAPI) => {
  try {
    getAuthData(`${process.env.REACT_APP_API_URL}/api/ezflip/v1/flip/${localStorage.getItem("assignment_id")}`).then((res) => {
      if (res.success) {
        thunkAPI.dispatch(setFlipStatus(res.status));
        if (res.status === "In-Progress") {
          thunkAPI.dispatch(statRept());
          thunkAPI.dispatch(setFileText(null));
          // console.log("stat Rept fn called ");
        } else if (res.status === "Done") {
          thunkAPI.dispatch(setDwnldLink(res?.download_link));
          thunkAPI.dispatch(setFileText(null));
        } else {
          toast.error(res.message);
          thunkAPI.dispatch(setFileText(null));
        }
      } else {
        toast.error(res?.detail);
        localStorage.removeItem("assignment_id");
        thunkAPI.dispatch(setFileText(null));

        // window.location.reload();
        // setStatData(null);
        // navigate("/ez_count");
      }
    });
  } catch (error) {
    console.log(error);
    return error;
  }
});

export const uploadFile = createAsyncThunk("flip-data/uploadFile", async (args, thunkAPI) => {
  try {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/ezflip/v1/upload-file/`,
      data: args,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      // thunkAPI.dispatch(setFileText("Waiting to Upload..."));

      let data = {
        assignment_id: res.data.data.assignment_id,
      };
      // data post here .......
      let URL = `${process.env.REACT_APP_API_URL}/api/ezflip/v1/flip/`;
      postAuthData(URL, data).then((res) => {
        if (res.success) {
          localStorage.setItem("assignment_id", res.data.assignment_id);
          // console.log("Status API hit here");
          thunkAPI.dispatch(statusAPI());
        } else {
          toast.error(res.message);
          setTimeout(() => window.location.reload(), 2000);
        }
      });
    });
  } catch (error) {
    console.error(`Failed to get upload FIle`);
  }
});

const ezFlipSlice = createSlice({
  name: "flip-data",
  initialState,
  reducers: {
    setFlipCurPage: (state, { payload }) => {
      state.flipCurPage = payload;
    },
    setFlipTotalPage: (state, { payload }) => {
      state.totalPage = payload;
    },
    setFileText: (state, { payload }) => {
      state.flip_data.file_text = payload;
    },
    setFlipStatus: (state, { payload }) => {
      state.flip_data.flip_status = payload;
    },
    setDwnldLink: (state, { payload }) => {
      state.flip_data.dwnld_link = payload;
    },
  },
  extraReducers: {
    [getFlipHistoryData.pending]: (state) => {
      state.history_data.loader = true;
    },
    [getFlipHistoryData.fulfilled]: (state, { payload }) => {
      state.history_data.loader = false;
      state.history_data.data = payload;
    },
    [getFlipHistoryData.rejected]: (state, { payload }) => {
      state.history_data.loader = false;
      state.history_data.error = payload;
    },
  },
});

export const { setFlipCurPage, setFlipTotalPage, setFileText, setFlipStatus, setDwnldLink } = ezFlipSlice.actions;

export default ezFlipSlice.reducer;
