import React, { useState } from "react";
import "./CheckboxInput.scss";

const CheckboxInput = ({ id, name, defaultChecked, onChange, label, disabled }) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleCheckboxChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);

    if (onChange) {
      onChange(newChecked, id); // Call the onChange callback with the updated value
    }
  };

  return (
    <label htmlFor={id} className="checkbox">
      {label}
      <input type="checkbox" id={id} name={name} checked={checked} onChange={handleCheckboxChange} disabled={disabled} />
      <span className="checkmark"></span>
    </label>
  );
};

export default CheckboxInput;
