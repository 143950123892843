import React from "react";
import "./WordCountPagination.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changeWCPage } from "../../../../../store/features/ezSearchSlices/wordCountDataSlice/wordCountDataSlice";

const WordCountPagination = () => {
  const dispatch = useDispatch();
  const { word_count_data, curPage } = useSelector((state) => state.wordCountData);
  function pageNav(val) {
    dispatch(changeWCPage(curPage + val));
  }

  return (
    <div className="word-count-pagination">
      <button
        type="prev"
        id="wc-prev-btn"
        disabled={curPage === 1 ? true : false}
        style={curPage === 1 ? { color: "grey" } : {}}
        onClick={() => pageNav(-1)}
      >
        <FaAngleLeft />
        Previous
      </button>
      <span>
        {curPage} of {word_count_data?.data?.data?.total_page}
      </span>
      <button
        type="next"
        id="wc-next-btn"
        disabled={curPage === word_count_data?.data?.data?.total_page ? true : false}
        style={curPage === word_count_data?.data?.data?.total_page ? { color: "grey" } : {}}
        onClick={() => pageNav(1)}
      >
        Next
        <FaAngleRight />
      </button>
    </div>
  );
};

export default WordCountPagination;
