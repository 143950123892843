export const createOptionsArr = (data) => {
  let options_format_data = data.map((cur) => {
    return { label: `${cur.email_id} - ${cur.name ? cur.name : "NA"}`, value: cur.resource_id };
  });
  return options_format_data;
};

export const initialState = {
  user_data: {
    resource_id: null,
    workspace_user_list: {
      data: [],
      loading: null,
      error: null,
    },
    loading: null,
    error: null,
    success: null,
  },
};
