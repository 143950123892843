export const customSelectTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "var(--prime-blue)", // outline color of the input
      // primary75: "#4C9AFF",
      primary50: "var(--prime-blue)", // on click color of item
      primary25: "hsl(0, 0%, 30%)", // active/hover item color
      // danger: "var(--prime-blue)", // item cross icon color
      // dangerLight: "var(--prime-blue)", // item cross background color
      neutral0: "#707070", // color of select options background as well as the input background
      //       neutral5: 'hsl(0, 0%, 95%)',
      // neutral10: "#3c3c77", // this is item background color
      // neutral20: "var(--prime-blue)", // border color for the input
      //   neutral30: "hsl(0, 0%, 30%)", // hover color for the input
      //       neutral40: 'var(--dark-blue)', // no options message color
      neutral50: "hsl(0, 0%, 70%)", // placeholder color
      //       neutral60: 'hsl(0, 0%, 40%)', // all cross button
      //       neutral70: 'hsl(0, 0%, 30%)',
      // neutral80: "var(--dark-blue)", // item text color and icon color
      //       neutral90: 'hsl(0, 0%, 10%)',
    },
  };
};

export const customSelectStyles = {
  // control: (styles) => ({ ...styles, backgroundColor: "#707070" }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#707070",
    border: "none", // Remove border on hover and focus
    boxShadow: "none", // Remove box-shadow on hover and focus
    ":hover": {
      ...styles[":hover"], // Preserve other hover styles
      border: "none", // Remove border on hover
      boxShadow: "none", // Remove box-shadow on hover
    },
  }),
  option: (styles) => ({
    ...styles,
    color: "#fff",
    cursor: "pointer",
  }),
  multiValue: (styles) => {
    return { ...styles, backgroundColor: "#000", color: "#fff" };
  },
  multiValueLabel: (styles) => {
    return { ...styles, color: "#fff" };
  },
  multiValueRemove: (styles) => {
    return {
      ...styles,
      color: "#00",
      cursor: "pointer",
      ":hover": { color: "#fff" },
    };
  },
  singleValue: (styles) => ({
    ...styles,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    cursor: "pointer",
    color: "var(--prime-blue)",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none", // Hide the indicator separator
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "hsl(0,0%,70%)" : "var(--prime-blue)", // Customize indicator color based on disabled state
    // cursor: "pointer",
    // Add other styles for the dropdown indicator here if needed
  }),
  menu: (provided) => ({
    ...provided,
  }),

  valueContainer: (styles) => ({
    ...styles,
  }),
};
