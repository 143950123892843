import React, { useEffect } from "react";
import "./HistoryPagination.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../../../../../store/features/ezSearchSlices/fetchHistorySlice/fetchHistorySlice";

const HistoryPagination = () => {
  const dispatch = useDispatch();
  const { history_data, curPage } = useSelector((state) => state.fetchHistoryData);

  // below function will change the state "curPage" either by 1 or -1..
  function pageNav(val) {
    dispatch(changePage(curPage + val));
  }

  // below function will disable the prevBtn and nextBtn as per the curPage value ..

  useEffect(() => {
    let preBtn = document.getElementById("prev-btn");
    let nextBtn = document.getElementById("next-btn");
    if (curPage <= 1) {
      preBtn.disabled = true;
      preBtn.style.color = "grey";
      nextBtn.disabled = false;
    } else if (curPage < history_data?.data?.total_page) {
      preBtn.disabled = false;
      nextBtn.disabled = false;
    } else {
      preBtn.disabled = false;
      nextBtn.disabled = true;
      nextBtn.style.color = "grey";
    }
  }, [curPage]);

  return (
    <div className="history-pagination">
      <button type="prev" id="prev-btn" onClick={() => pageNav(-1)}>
        <FaAngleLeft />
        Previous
      </button>
      <span className="page-display">
        {curPage} of {history_data?.data?.total_page}
      </span>
      <button type="next" id="next-btn" onClick={() => pageNav(1)}>
        Next
        <FaAngleRight />
      </button>
    </div>
  );
};

export default HistoryPagination;
