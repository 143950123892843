import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { getAuthData, postAuthData } from "../../../../services/request";

import { initialState } from "./common";

export const getWordCountData = createAsyncThunk("word-count-data/getWordCountData", async (args) => {
  // console.log(args);
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/web-data/${args.assId}/?page=${args.page}`;
  let data = {
    search_phrase_data: args.search_phrase_data,
    search_link_data: args.search_link_data,
  };
  try {
    const res = await postAuthData(url, data);
    if (res.success) {
      return res;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      return toast.error("Failed To Get Word Count Data!");
    }
  } catch (err) {
    // toast.error(`Failed To Get Unsubmitted Data!`);
    return console.error(`Failed To Get Word Count Data!`);
  }
});

export const getExcelFile = createAsyncThunk("word-count-data/getExcelFile", async (ASSIGNMENTID) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/file-download/${ASSIGNMENTID}/`;

  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res;
    }
  } catch (err) {
    console.error(`Failed to Download File`);
  }
});

const wordCountDataSlice = createSlice({
  name: "word-count-data",
  initialState,
  reducers: {
    changeWCPage: (state, { payload }) => {
      state.curPage = payload;
    },
    resetWordCountData: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getWordCountData.pending]: (state) => {
      state.word_count_data.loader = true;
    },
    [getWordCountData.fulfilled]: (state, { payload }) => {
      state.word_count_data.loader = false;
      // console.log(payload);
      state.word_count_data.data = payload;
      state.search_phrase_data = payload.data.search_phrase_data;
    },
    [getWordCountData.rejected]: (state, { payload }) => {
      state.word_count_data.loader = false;
      state.word_count_data.error = payload;
    },
    [getExcelFile.pending]: (state) => {
      state.file_download_data.loader = true;
    },
    [getExcelFile.fulfilled]: (state, { payload }) => {
      state.file_download_data.loader = false;
      // console.log(payload);
      state.file_download_data.assignment_id = payload.assignment_id;
      state.file_download_data.success = payload.success;
      state.file_download_data.status = payload.status;
      state.file_download_data.message = payload.message;
      state.file_download_data.download_link = payload.download_link;
    },
    [getExcelFile.rejected]: (state, { payload }) => {
      state.file_download_data.loader = false;
      state.file_download_data.error = payload;
    },
  },
});

export const { changeWCPage, resetWordCountData } = wordCountDataSlice.actions;

export default wordCountDataSlice.reducer;
