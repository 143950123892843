import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./HistorySection.scss";
import LoaderModal from "../../../../utils/Components/LoaderModal/LoaderModal";
import { changePage, getHistoryData } from "../../../../store/features/ezSearchSlices/fetchHistorySlice/fetchHistorySlice";
// import HistoryPagination from "./Components/HistoryPagination/HistoryPagination";
import HistoryInfo from "./Components/HistoryInfoWithPag/HistoryInfo";
const HistorySection = () => {
  const dispatch = useDispatch();
  const { history_data, curPage } = useSelector((state) => state.fetchHistoryData);

  // When HistorySection appear first time, changing curPage value to 1 ...
  useEffect(() => {
    dispatch(changePage(1));
  }, []);

  // when curPage value change , we are getting history data through API hit ...
  useEffect(() => {
    if (curPage) dispatch(getHistoryData(curPage));
  }, [curPage]);
  return (
    <section className="history-tab-section">
      <div className="history-tab-center-box container-box">
        <div className="history-text">HISTORY</div>
        <section className="history-tab-center-box-section">
          <div className="history-tab-header">
            <h3 style={{ textAlign: "left" }}>Date</h3>
            <h3 style={{ textAlign: "left" }}>Website URL</h3>
            <h3>Check</h3>
            <h3>Search & Count</h3>
            <h3>File Download</h3>
          </div>
          {history_data.loader ? <LoaderModal width={"82vw"} verticleHeight={"53.4vh"} /> : <HistoryInfo />}
          {/* {history_data && <HistoryPagination />} */}
        </section>
      </div>
    </section>
  );
};

export default HistorySection;
