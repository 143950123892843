import moment from "moment";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAssignmentData } from "../../../../../../store/features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import ContentShortenComp from "../../../../../../utils/functions/ContentShortenComp";
import "./HistoryContent.scss";

const HistoryContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { history_data } = useSelector((state) => state.fetchHistoryData);

  // when any cell of History Data clicked, do two things
  // 1. Hitting fetchAssignment API .
  // 2. Navigate to Page as per the given asssignment
  function onNavigateToAssignment(assId) {
    localStorage.setItem("assignment_id", assId);
    dispatch(fetchAssignmentData(assId))
      .then((res) => {
        if (res?.error) {
          throw res.payload;
        } else {
          navigate(`/ez_search/${assId}`);
        }
      })
      .catch((err) => console.error("Error while navigation" || err));
  }

  return (
    <section id="history-section">
      {history_data?.data?.data?.map((cell, id) => {
        return (
          <div className="history-item" key={id} onClick={() => onNavigateToAssignment(cell?.assignment_id)}>
            <h4>{moment(new Date(cell?.created_at)).format("DD MMM YYYY") || "-"}</h4>
            <h4>
              <ContentShortenComp content={cell?.search_url} max_length={40} last_chars_length={3} />
            </h4>

            <h4>
              {cell?.search_type === "page" ? "This Page" : cell?.search_type === "entire" ? "Entire Website" : "All Links with Above URL"}
            </h4>
            <h4>
              {cell?.search_count_data?.url_count || "0"} Links :{" "}
              {cell?.search_count_data?.total_word_count ? cell?.search_count_data?.total_word_count.toLocaleString() : "0"} Words
            </h4>
            <h4>{cell?.total_download_file ? cell?.total_download_file + " Files" : "N/A"} </h4>
          </div>
        );
      })}
    </section>
  );
};

export default HistoryContent;
