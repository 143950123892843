import HistoryBtn from "./SearchHistory/HistoryBtn/HistoryBtn";
import SearchForm from "./SearchComp/SearchForm/SearchForm";
import "./Search.scss";

const Search = () => {
  return (
    <section id="ez-search-main-section">
      <div className="ez-search-center-box container-layout">
        <HistoryBtn />
        <SearchForm />
      </div>
    </section>
  );
};

export default Search;
