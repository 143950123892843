const ContentShortenComp = ({ content, max_length, last_chars_length = 5 }) => {
  return (
    <span title={content?.length > max_length ? content : ""}>
      {`${
        content?.length > max_length
          ? content?.substr(0, max_length) + "..." + content?.substr(content?.length - last_chars_length, content?.length)
          : content
      }`}
    </span>
  );
};

export default ContentShortenComp;
