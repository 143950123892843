// import fileContext from "../../../../data/ez_search/download_file_data.json";

export const initialState = {
  file_context_data: {
    data: null,
    loader: null,
    error: null,
  },
  file_type_list: null,
  total_files: null,
  file_name: null,
  curFileContextPage: null,
  totalFileContextPage: 1,
};
