// ========================== SLICE ======================

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./common";
import { getAuthData, postAuthData } from "../../../services/request";
import { toast } from "react-hot-toast";

export const getCountHistoryData = createAsyncThunk("count-data/getCountHistoryData", async (args, thunkAPI) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezcount/v1/word-count/?page=${args}`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      thunkAPI.dispatch(setTotalCountPage(res.total_page));
      return res;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      toast.error("error in getting CountHistory Data ");
    }
  } catch (err) {
    toast.error(`error in getting CountHistory Data `);
  }
});

export const getCountFileData = createAsyncThunk("count-data/getCountFileData", async (data) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezcount/v1/word-count/`;

  try {
    const res = await postAuthData(url, data);
    if (res.success) {
      localStorage.setItem("assignment_id", res.data.assignment_id);
      return res;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      toast.error("error in getting Count Data ");
    }
  } catch (err) {
    toast.error("error in getting Count Data ");
  }
});

const countDataSlice = createSlice({
  name: "count-data",
  initialState,
  reducers: {
    resetCountFormData: (state) => {
      state.count_form_data = initialState.count_form_data;
    },
    resetUserTab: () => {
      return initialState;
    },
    handleCountMainAccess: (state, { payload }) => {
      state.count_form_page_access = payload;
    },
    setCurCountPage: (state, { payload }) => {
      state.curCountPage = payload;
    },
    setTotalCountPage: (state, { payload }) => {
      state.totalCountPage = payload;
    },
  },
  extraReducers: {
    [getCountHistoryData.pending]: (state) => {
      state.count_history_data.loader = true;
    },
    [getCountHistoryData.fulfilled]: (state, { payload }) => {
      state.count_history_data.loader = false;
      state.count_history_data.data = payload;
    },
    [getCountHistoryData.rejected]: (state, { payload }) => {
      state.count_history_data.loader = false;
      state.count_history_data.error = payload;
    },
    [getCountFileData.pending]: (state) => {
      state.count_form_data.loader = true;
    },
    [getCountFileData.fulfilled]: (state, { payload }) => {
      state.count_form_data.loader = false;
      // console.log(payload);
      // data: {
      //   assignment_id: null,
      //   source_language: null,
      //   file_name: null,
      //   include_counts: null,
      // },
      state.count_form_data.data.assignment_id = payload.data.assignment_id;
      state.count_form_data.data.source_language = payload.data.source_language;
      state.count_form_data.data.file_name = payload.data.file_name;
      state.count_form_data.data.include_counts = payload.data.include_counts;
    },
    [getCountFileData.rejected]: (state, { payload }) => {
      state.count_form_data.loader = false;
      state.count_form_data.error = payload;
    },
  },
});

export const {
  //user tab switch
  toggleUserTab,
  openUserForm,
  openUserList,
  openEditUserForm,
  handleCountMainAccess,
  // reset user form data
  resetUserFormData,
  resetUserTab,
  // Form Details ACTIONS
  addFirstUserName,
  addLastUserName,
  addUserEmail,
  addUserPhone,
  addUserDefaultEntity,
  addUserAddressCountry,
  addUserAddressCity,

  setCurCountPage,
  setTotalCountPage,
} = countDataSlice.actions;

export default countDataSlice.reducer;
