import "./LogoutModal.scss";
import { NavLink } from "react-router-dom";
import { postAuthData } from "../../../../services/request";
import { useDispatch } from "react-redux";
import { handleSearchMainAccess, resetStateData } from "../../../../store/features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../../contexts/AuthContext";

const LogoutModal = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth();

  const handleLogout = () => {
    postAuthData(`${process.env.REACT_APP_API_URL}/api/auth/v1/logout/`).then((res) => {
      if (res.success === true) {
        // clearing search feature state ....
        dispatch(handleSearchMainAccess(false));
        dispatch(resetStateData());

        window.localStorage.clear();
        logout();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div className="logout-section">
      <NavLink onClick={handleLogout} className="navbar-item" to="/">
        Logout
      </NavLink>
    </div>
  );
};

export default LogoutModal;
