import phraseCountFilterData from "../../../../data/ez_search/phrase_count.json";
import fileCountFilterData from "../../../../data/ez_search/file_count.json";

export const initialState = {
  word_count_filter_data: {
    data: phraseCountFilterData,
    loader: null,
    error: null,
    show_wc_filter_modal: false,
    show_file_tab_filter_modal: false,
  },
  file_count_filter_data: {
    data: fileCountFilterData,
    loader: null,
    error: null,
  },
  is_filter_data: true,
};
