import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setFileName } from "../../../store/features/utilSlice/utilSlice";
import { ACTIVE_MENU } from "../../namespaces/ACTIVE_TAB";
import toast from "react-hot-toast";
// import { MdCancel } from "react-icons/md";
// import { FileContext } from "../../../AppRoutes";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 7,
  borderColor: "var(--prime-blue)",
  borderStyle: "dashed",
  backgroundColor: "#112949",
  color: "var(--prime-blue)",
  transition: "border .3s ease-in-out",
  height: "25vh",
  fontSize: "var(--m-font)",
  width: "100%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DragnDropUpload = () => {
  // const { files, setFiles } = useContext(FileContext);
  // const [files] = useState(null);
  const dispatch = useDispatch();
  const { file_info } = useSelector((state) => state.utilData);
  const { active_menu } = useSelector((state) => state.searchFormData);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const isPdfFile = uploadedFile.path.endsWith(".pdf");
      const isCountMenu = active_menu === ACTIVE_MENU.COUNT;

      if (isPdfFile && !isCountMenu) {
        toast.error("You can't upload pdf here.");
        return;
      }

      dispatch(setFileName(acceptedFiles));
    },
    [active_menu, file_info],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    disabled: file_info ? true : false,
    multiple: false,
    accept: {
      "text/*": [".docx", ".pptx", ".xlsx", ".pdf"],
    },
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <>
      {/* <div style={{ color: "var(--prime-blue)" }}>
        <MdCancel onClick={handleRemove} />
      </div> */}
      <div {...getRootProps({ style })}>
        {!file_info && (
          <>
            <input {...getInputProps()} />
            <div>Click here to upload</div>
            <div>or</div>
            <div>Drag and drop files here to upload</div>
          </>
        )}
        {file_info?.length > 0 && <div>File Uploaded: {file_info[0].path}</div>}
      </div>
    </>
  );
};

export default DragnDropUpload;
