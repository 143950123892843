import "./LoaderModal.scss";

const LoaderModal = ({ width, verticleHeight }) => {
  return (
    <div className="loader-modal" style={{ width: width, height: verticleHeight }}>
      <div></div>
    </div>
  );
};

export default LoaderModal;
