import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAuthData } from "../../../../services/request";
// import { postUserData } from "../searchFormDataSlice/searchFormDataSlice";

import { initialState } from "./common";

// +++++++++ OnClick Links Redirect Data api  +++++++++++++
export const fetchFileContentData = createAsyncThunk("file-context-data/fetchAssignmentData", async (args) => {
  // console.log(args);
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/file-data/${args.assId}/?page=${args.page}`;
  let data = {
    search_file_type: args.search_file_type,
    search_file_name: args.search_file_name,
  };
  try {
    const res = await postAuthData(url, data);
    if (res.success) {
      return res;
    }
  } catch (err) {
    console.error(`Failed to get Assignment Data`);
  }
});

const fileContextSlice = createSlice({
  name: "file-context-data",
  initialState,
  reducers: {
    changeFileContextPage: (state, { payload }) => {
      state.curFileContextPage = payload;
    },
    searchFileName: (state, { payload }) => {
      // console.log(payload);
      state.file_name = payload;
    },
    resetFileInfoData: () => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchFileContentData.pending]: (state) => {
      state.file_context_data.loader = true;
    },
    [fetchFileContentData.fulfilled]: (state, { payload }) => {
      state.file_context_data.loader = false;
      state.file_context_data.data = payload;
      state.totalFileContextPage = payload.data.total_page;
      state.file_type_list = payload.data.search_file_type;
      state.total_files = payload.data.total_file_count;
    },
    [fetchFileContentData.rejected]: (state, { payload }) => {
      state.file_context_data.loader = false;
      state.file_context_data.error = payload;
    },
  },
});

export const { handleWordCountFilterModal, changeFileContextPage, searchFileName, resetFileInfoData } = fileContextSlice.actions;

export default fileContextSlice.reducer;
