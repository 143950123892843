import React, { createContext, useContext } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const login = () => {
    localStorage.setItem("isAuthenticated", true);
  };

  const logout = () => {
    localStorage.setItem("isAuthenticated", false);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
