import React, { useEffect } from "react";
import Rows from "./FlipRows";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./FlipHistory.scss";
import { useDispatch, useSelector } from "react-redux";
import { getFlipHistoryData, setFlipCurPage } from "../../../store/features/ezFlipSlice/ezFlipSlice";
import LoaderModal from "../../../utils/Components/LoaderModal/LoaderModal";

const FlipHistoryInfo = () => {
  const { history_data } = useSelector((state) => state.flipData);

  return (
    <>
      <div className="history_table_container">
        <div className="heading_container">
          <div className="table_heading">Date</div>
          <div className="table_heading">Source File Name</div>
          <div className="table_heading">Flipped File</div>
        </div>

        {history_data?.loader ? (
          <LoaderModal width={"85vw"} verticleHeight={"60vh"} />
        ) : (
          history_data?.data?.map((rowData) => (
            <Rows
              key={rowData?.assignment_id}
              assgn_Id={rowData?.assignment_id}
              date={rowData?.created_at}
              file_name={rowData?.file_name}
            />
          ))
        )}
      </div>
    </>
  );
};

const FlipHistory = () => {
  const dispatch = useDispatch();
  const { flipCurPage, history_data, totalPage } = useSelector((state) => state.flipData);

  useEffect(() => {
    if (flipCurPage) {
      // console.log(flipCurPage);
      dispatch(getFlipHistoryData(flipCurPage));
    }
  }, [flipCurPage]);

  useEffect(() => {
    dispatch(setFlipCurPage(1));
  }, []);

  const handlePage = (pageVal) => {
    dispatch(setFlipCurPage(flipCurPage + pageVal));
  };

  return (
    <>
      <div className="history_big_container">
        <div className="flip-history-heading">HISTORY</div>
        <div className="body_container">
          <div className="history_small_container">
            <FlipHistoryInfo />
          </div>
          {!history_data?.loader && (
            <div className="pagination">
              <button
                type="prev"
                id="prev-btn"
                onClick={() => handlePage(-1)}
                disabled={flipCurPage === 1 ? true : false}
                style={flipCurPage === 1 ? { color: "grey" } : {}}
              >
                <FaAngleLeft />
                Previous
              </button>
              <span>
                {flipCurPage} of {totalPage}
              </span>
              <button
                type="next"
                id="next-btn"
                onClick={() => handlePage(1)}
                disabled={flipCurPage === totalPage ? true : false}
                style={flipCurPage === totalPage ? { color: "grey" } : {}}
              >
                Next
                <FaAngleRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FlipHistory;
