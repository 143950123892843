import { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterFileContentData,
  handleFileTabFilterModal,
} from "../../../../../store/features/ezSearchSlices/filterDataSlice/filterDataSlice";
import { fetchFileContentData } from "../../../../../store/features/ezSearchSlices/fileContextSlice/fileContextSlice";
// import { handleSelectionAll, handleSelectionNone } from "../../../../../utils/functions/handleSelection";
import "./FileDownloadTabFilter.scss";
// import { BsCheck } from "react-icons/bs";

const FileDownloadTabFilter = () => {
  const dispatch = useDispatch();
  const { file_count_filter_data, is_filter_data } = useSelector((state) => state.filterData);
  const { curFileContextPage, file_type_list } = useSelector((state) => state.fileContext);

  const [updateFileList, setUpdateFileList] = useState([]);

  function filterData(file) {
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curFileContextPage,
      search_file_type: file,
      search_file_name: "",
    };
    dispatch(fetchFileContentData(data));
    dispatch(handleFileTabFilterModal(false));
  }

  function selectCheckBox(e) {
    // if (e.target.checked) {
    if (!updateFileList.includes(e.target.name)) {
      if (e.target.checked) {
        // console.log("checked", e.target.name);
        setUpdateFileList((preVal) => [...preVal, e.target.name]);
      }
      // }
    } else {
      // console.log("unchecked", e.target.name);
      // console.log(updateFileList, updateFileList.indexOf(e.target.name));
      const splice_idx = updateFileList.indexOf(e.target.name);
      const new_updated_file_list = [...updateFileList];
      new_updated_file_list.splice(splice_idx, 1);
      setUpdateFileList(new_updated_file_list);
      // console.log(updateFileList);
    }
  }

  // useEffect(() => {
  //   console.log(updateFileList);
  // }, [updateFileList]);

  function handleSelectionAll() {
    let data = file_count_filter_data?.data?.data;
    let actualData = Object.keys(data);
    setUpdateFileList(actualData);
  }

  function handleSelectionNone() {
    setUpdateFileList([]);
  }

  useEffect(() => {
    let assId = localStorage.getItem("assignment_id");
    if (is_filter_data) dispatch(getFilterFileContentData(assId));
  }, []);

  useEffect(() => {
    if (file_type_list) {
      setUpdateFileList(file_type_list);
    }
  }, [file_type_list]);

  return (
    <section id="file-filter-tab-section">
      <div className="file-filter-header">
        <span className="file-filter-title">
          <FaFilter className="file-filter-icon" />
          Filter File Type
        </span>
        <span className="file-cross-icon" onClick={() => dispatch(handleFileTabFilterModal(false))}>
          <RxCross2 />
        </span>
      </div>
      <div className="file-select-option">
        <span className="file-select-all" onClick={handleSelectionAll}>
          Select All
        </span>
        <span className="file-select-none" onClick={handleSelectionNone}>
          Select None
        </span>
      </div>
      {file_count_filter_data?.loader === true ? (
        <span className="loading-style">Loading..</span>
      ) : (
        <div className="file-phrases-checkbox">
          {Object.keys(file_count_filter_data.data.data).map((file, id) => {
            return (
              <div className="file-phrases-list" key={id}>
                <div className="chkbx-label">
                  <input
                    type="checkbox"
                    id={file}
                    name={file}
                    data-name={file}
                    onChange={(e) => selectCheckBox(e)}
                    className="file-phrase-item filter-box filter-ctrl"
                  />

                  {updateFileList?.map((cur) => {
                    return <>{cur === file && <img id={`check-${id}`} src="/resources/check.png" className="check-icon" />} </>;
                  })}

                  <p className="file-type" name={file} data-name={file}>
                    {file.toUpperCase()} ({file_count_filter_data.data.data[file]} FILES)
                  </p>
                </div>
              </div>
            );
          })}
          <button className="filter-btn" onClick={() => filterData(updateFileList)}>
            Apply Filter
          </button>
        </div>
      )}
    </section>
  );
};

export default FileDownloadTabFilter;
