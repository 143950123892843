import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthData } from "../../../../services/request";

import { initialState } from "./common";

export const getFilterWordCountData = createAsyncThunk("filter-data/getFilterWordCountData", async (ASSIGNMENTID) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/phrase-count/${ASSIGNMENTID}/`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res;
    }
  } catch (err) {
    console.error(`Failed to get Filter Data`);
    return `Failed to get Filter Data`;
  }
});

export const getFilterFileContentData = createAsyncThunk("filter-data/getFilterFileContentData", async (ASSIGNMENTID, thunkAPI) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/file-count/${ASSIGNMENTID}/`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      thunkAPI.dispatch(setFilterData());
      return res;
    }
  } catch (err) {
    console.error(`Failed to get Filter Data`);
    return `Failed to get Filter Data`;
  }
});

const filterDataSlice = createSlice({
  name: "filter-data",
  initialState,
  reducers: {
    handleWordCountFilterModal: (state, { payload }) => {
      state.show_wc_filter_modal = payload;
    },
    handleFileTabFilterModal: (state, { payload }) => {
      state.show_file_tab_filter_modal = payload;
    },
    setFilterData: (state) => {
      state.is_filter_data = false;
    },
    resetFilterData: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getFilterWordCountData.pending]: (state) => {
      state.word_count_filter_data.loader = true;
    },
    [getFilterWordCountData.fulfilled]: (state, { payload }) => {
      state.word_count_filter_data.loader = false;
      // console.log(payload);
      state.word_count_filter_data.data = payload;
    },
    [getFilterWordCountData.rejected]: (state, { payload }) => {
      state.word_count_filter_data.loader = false;
      state.word_count_filter_data.error = payload;
    },
    [getFilterFileContentData.pending]: (state) => {
      state.file_count_filter_data.loader = true;
    },
    [getFilterFileContentData.fulfilled]: (state, { payload }) => {
      state.file_count_filter_data.loader = false;
      // console.log(payload);
      state.file_count_filter_data.data = payload;
    },
    [getFilterFileContentData.rejected]: (state, { payload }) => {
      state.file_count_filter_data.loader = false;
      state.file_count_filter_data.error = payload;
    },
  },
});

export const { handleWordCountFilterModal, handleFileTabFilterModal, resetFilterData, setFilterData } = filterDataSlice.actions;

export default filterDataSlice.reducer;
