// ========================== SLICE ======================

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  file_info: null,
};

const utilSlice = createSlice({
  name: "util-data",
  initialState,
  reducers: {
    setFileName: (state, { payload }) => {
      state.file_info = payload;
    },
    resetutilData: () => {
      return initialState;
    },
  },
  extraReducers: {},
});

export const { setFileName, resetutilData } = utilSlice.actions;

export default utilSlice.reducer;
