import React, { useContext, useEffect, useState } from "react";
// import Navbar from "../../../components/navbar/Navbar";
import "./CountInfo.scss";
import { FaInfoCircle } from "react-icons/fa";
import { CountFileInfoContext } from "../../../AppRoutes";
import { getAuthData } from "../../../services/request";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { handleCountMainAccess } from "../../../store/features/ezCountSlice/ezCountSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import { ACTIVE_MENU } from "../../../utils/namespaces/ACTIVE_TAB";
// import count_data from "../../../dummy/ez_count/get-count-info.json";

function CountInfo() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { countFileData } = useContext(CountFileInfoContext);
  const [countData, setCountData] = useState(null);
  const [status, setStatus] = useState(null);
  const [statData, setStatData] = useState(null);
  const { assignment_id } = useParams();
  const { count_form_page_access } = useSelector((state) => state.countData);

  // const { active_menu } = useSelector((state) => state.searchFormData);

  useEffect(() => {
    // checking if data is coming after history tab btn click
    if (!countFileData) {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api/ezcount/v1/word-count/${
          assignment_id ? assignment_id : localStorage.getItem("assignment_id")
        }`,
      ).then((res) => {
        if (res.success) {
          setCountData(res);
        } else {
          localStorage.removeItem("assignment_id");
        }
      });
      // checking if data is coming after count words btn click
    } else if (countFileData) {
      setCountData(countFileData);
    }
  }, [assignment_id]);

  function statusAPI() {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api/ezcount/v1/status/${assignment_id ? assignment_id : localStorage.getItem("assignment_id")}`,
    ).then((res) => {
      if (res.success) {
        setStatus(res.status);
        if (res.status === "In-Progress") {
          statRept();
        } else if (res.status === "Done") {
          setStatData(res?.data);
        } else if (res.status === "Error") {
          toast.error("Error Loading Data");
          setStatData(res?.data);
        }
      } else {
        toast.error(res?.detail);
        localStorage.removeItem("assignment_id");
        setStatData(null);
      }
    });
  }

  function statRept() {
    var intvId = setInterval(() => {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api/ezcount/v1/status/${assignment_id ? assignment_id : localStorage.getItem("assignment_id")}`,
      ).then((res) => {
        if (res.success) {
          setStatus(res.status);
          if (res.status === "Done" || res.status === "Error") {
            setStatData(res?.data);
            clearInterval(intvId);
          }
        }
      });
    }, 10 * 1000);
  }

  useEffect(() => {
    statusAPI();
  }, []);

  useEffect(() => {
    if (assignment_id) localStorage.setItem("assignment_id", assignment_id);
    if (!count_form_page_access) dispatch(handleCountMainAccess(true));
  }, []);

  const toggleCountPopup = () => {
    setIsOpen(!isOpen);
  };

  const Popup = () => {
    return (
      <div className="popup_box">
        <div className="popup_box_content">
          <div className="popup_label">Include Counts</div>
          {countData?.include_counts?.map((item, index) => (
            <div key={index} className="popup-item">
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="count-info-container">
        <div className="display_info">
          <div className="translated_info count-info-file-name">
            <div className="count-info-file-title">File Name</div>
            <div className="count-info-file-info">{countData ? countData?.file_name || "-" : "Loading..."}</div>
          </div>
          <div className="translated_info_section">
            <div className="translated_info_container">
              <div className="translated_info">
                <div className="translated_info_label">Include</div>

                {Array.isArray(countData?.include_counts) ? (
                  <div className="translated_info_value">
                    {countData?.include_counts?.length || 0} Counts
                    <div className="show_tip">
                      <FaInfoCircle className="info_icon" onClick={toggleCountPopup} />
                      {isOpen && <Popup />}
                    </div>
                  </div>
                ) : status === "Error" ? (
                  "-"
                ) : (
                  "Loading..."
                )}
              </div>
              <div className="translated_info">
                <div className="translated_info_label">Source Language</div>
                <div className="translated_info_value">
                  {countData?.source_language
                    ? countData?.source_language.charAt(0).toUpperCase() + countData?.source_language.slice(1)
                    : status === "Error"
                    ? "-"
                    : "Loading..."}
                  {/* {!countData && "Loading..."} */}
                </div>
              </div>
              <div className="translated_info">
                <div className="translated_info_label">Word Count ( Source )</div>
                <div className="translated_info_value">
                  {countData?.word_count_data ? countData?.word_count_data?.word_count_source || 0 : statData?.word_count_source || " "}
                  {status === "In-Progress" && "Loading.."}
                  {status === "Error" && "0"}
                  {!countData && "Loading..."}
                </div>
              </div>
            </div>
            <div className="translated_info_container">
              <div className="translated_info">
                <div className="translated_info_label">Page Count ( Complete File )</div>
                <div className="translated_info_value">
                  {countData?.word_count_data ? countData?.word_count_data?.page_count || 0 : statData?.page_count || " "}
                  {status === "In-Progress" && "Loading.."}
                  {status === "Error" && "0"}
                  {!countData && "Loading..."}
                </div>
              </div>
              <div className="translated_info">
                <div className="translated_info_label">Word Count ( Complete File )</div>
                <div className="translated_info_value">
                  {countData?.word_count_data ? countData?.word_count_data?.word_count_total || 0 : statData?.word_count_total || " "}
                  {status === "In-Progress" && "Loading.."}
                  {status === "Error" && "0"}
                  {!countData && "Loading..."}
                </div>
              </div>
              <div className="translated_info">
                <div className="translated_info_label">Unique Word Count ( Source )</div>
                <div className="translated_info_value">
                  {countData?.word_count_data ? countData?.word_count_data?.unique_word_count || 0 : statData?.unique_word_count || " "}
                  {status === "In-Progress" && "Loading.."}
                  {status === "Error" && "0"}
                  {!countData && "Loading..."}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CountInfo;
