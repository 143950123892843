import React from "react";
import downloadFiles from "../../../utils/functions/downloadFiles";
import "./FlipHistory.scss";
import moment from "moment";
import ContentShortenComp from "../../../utils/functions/ContentShortenComp";
import { getAuthData } from "../../../services/request";
import { toast } from "react-hot-toast";

function Rows(props) {
  function handleClick() {
    getAuthData(`${process.env.REACT_APP_API_URL}/api/ezflip/v1/flip/${props.assgn_Id}`).then((res) => {
      if (res.success) {
        if (res.status === "In-Progress") {
          statRept();
        } else if (res.status === "Done") {
          downloadFiles(res?.download_link);
        } else if (res.status === "Error") {
          toast.error("Cant download file as it does not exist!!");
        }
      } else {
        toast.error(res?.detail);
        localStorage.removeItem("assignment_id");
      }
    });

    function statRept() {
      var intvId = setInterval(() => {
        getAuthData(`${process.env.REACT_APP_API_URL}/api/ezflip/v1/flip/${props.assgn_Id}`).then((res) => {
          if (res.success) {
            if (res.status === "Done" || res.status === "Error") {
              downloadFiles(res?.download_link);
              clearInterval(intvId);
            }
          }
        });
      }, 10 * 1000);
    }
  }

  return (
    <>
      <div className="row_card glass-container">
        <div id="date">
          <div className="table_heading mobile_view_hide_heading">Date</div>
          <div id="date" className="grid-item">
            {moment(new Date(props.date)).format("DD MMM YYYY")}
          </div>
        </div>

        <div id="file_name">
          <div className="table_heading mobile_view_hide_heading" style={{ color: "#3CC3F2", cursor: "pointer" }}>
            File Name
          </div>
          <div id="file_name" className="grid-item">
            <ContentShortenComp content={props.file_name} max_length={30} last_chars_length={5} />
          </div>
        </div>

        <div id="file_dwnld_link_container" onClick={handleClick}>
          <div className="table_heading mobile_view_hide_heading">File</div>
          <div id="file_dwnld_link" style={{ color: "#3CC3F2", cursor: "pointer" }} className="grid-item">
            Download
          </div>
        </div>
      </div>
    </>
  );
}

export default Rows;
