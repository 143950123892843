import React from "react";
import Select from "react-select";
import { customSelectStyles, customSelectTheme } from "./customSelectInputStyles";

const SelectInputComp = ({ label, selectedValue, options, handleChange, placeholder, multi = false, disabled }) => {
  const isLabel = label === "Workspace Assignment ID"; // Check if label matches the given text
  const singleValueStyles = {
    color: "var(--white)",
    border: isLabel && "1px solid var(--prime-blue)",
    borderRadius: isLabel && "2px",
    padding: isLabel && "0.5vh",
    width: isLabel && "fit-content",
    backgroundColor: isLabel && "var(--prime-blue)",
    // Add other styles for the selected value here if needed
  };
  const valueContainerStyles = {
    padding: isLabel ? "2px" : "2px 8px",
  };

  return (
    <div className="select-container">
      {label && <div className="select-form-label">{label}</div>}
      <Select
        onChange={(option) => {
          handleChange(option);
        }}
        noOptionsMessage={() => "No options found."}
        placeholder={placeholder || "Select"}
        isMulti={multi}
        isSearchable
        value={selectedValue}
        options={options}
        isDisabled={disabled}
        className="select-form-select basic-multi-select"
        classNamePrefix="select"
        theme={customSelectTheme}
        //* conditionally redering custom styles
        styles={{
          ...customSelectStyles,
          singleValue: (base) => ({
            ...base,
            ...singleValueStyles,
          }),
          valueContainer: (base) => ({
            ...base,
            ...valueContainerStyles,
          }),
        }}
        //* to display dropdown menu list over other components
        menuPosition={"fixed"}
      />
    </div>
  );
};

export default SelectInputComp;
