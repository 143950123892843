import React from "react";
import "./FileInfoPagination.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changeFileContextPage } from "../../../../../store/features/ezSearchSlices/fileContextSlice/fileContextSlice";

const FileInfoPagination = () => {
  const dispatch = useDispatch();
  const { curFileContextPage, totalFileContextPage } = useSelector((state) => state.fileContext);

  function pageNav(val) {
    dispatch(changeFileContextPage(curFileContextPage + val));
  }

  return (
    <div className="file-info-pagination">
      <button
        type="prev"
        id="file-prev-btn"
        disabled={curFileContextPage === 1 ? true : false}
        style={curFileContextPage === 1 ? { color: "grey" } : {}}
        onClick={() => pageNav(-1)}
      >
        <FaAngleLeft />
        Previous
      </button>
      <span>
        {curFileContextPage} of {totalFileContextPage}
      </span>
      <button
        type="next"
        id="file-next-btn"
        disabled={curFileContextPage === totalFileContextPage ? true : false}
        style={curFileContextPage === totalFileContextPage ? { color: "grey" } : {}}
        onClick={() => pageNav(1)}
      >
        Next
        <FaAngleRight />
      </button>
    </div>
  );
};

export default FileInfoPagination;
