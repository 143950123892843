// import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { ImCancelCircle } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// import { FileContext } from "../../../AppRoutes";
import { setFileText, uploadFile } from "../../../store/features/ezFlipSlice/ezFlipSlice";
// import { getAuthData } from "../../../services/request";
import DragnDropUpload from "../../../utils/Components/DragnDropUpload/DragnDropUpload";
import "./FlipUpload.scss";
import { setFileName } from "../../../store/features/utilSlice/utilSlice";

const FlipUpload = () => {
  // const { files } = useContext(FileContext);
  const dispatch = useDispatch();
  const { file_info } = useSelector((state) => state.utilData);
  const { flip_data } = useSelector((state) => state.flipData);
  // const [status, setStatus] = useState(null);
  // const [dwlnFile, setDwlnFile] = useState(null);
  // const { assignment_id } = useParams();

  // function statusAPI() {
  //   getAuthData(
  //     `${process.env.REACT_APP_API_URL}api/ezflip/v1/flip/${assignment_id ? assignment_id : localStorage.getItem("assignment_id")}`,
  //   ).then((res) => {
  //     if (res.success) {
  //       setStatus(res.status);
  //       if (res.status === "In-Progress") {
  //         statRept();
  //       } else if (res.status === "Done") {
  //         setDwlnFile(res?.download_link);
  //         setFileText(null);
  //       } else {
  //         alert(res.message);
  //         setFileText(null);
  //       }
  //     } else {
  //       alert(res?.detail);
  //       localStorage.removeItem("assignment_id");
  //       setFileText(null);
  //       // window.location.reload();
  //       // setStatData(null);
  //       // navigate("/ez_count");
  //     }
  //   });
  // }

  // function statRept() {
  //   var intvId = setInterval(() => {
  //     getAuthData(
  //       `${process.env.REACT_APP_API_URL}api/ezcount/v1/status/${assignment_id ? assignment_id : localStorage.getItem("assignment_id")}`,
  //     ).then((res) => {
  //       if (res.success) {
  //         setStatus(res.status);
  //         if (res.status === "Done" || res.status === "Error") {
  //           setDwlnFile(res?.download_link);
  //           setFileText(null);
  //           clearInterval(intvId);
  //         }
  //       }
  //     });
  //   }, 10 * 1000);
  // }

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(setFileName(null));
  };

  // to change the state of btn to active
  function activeBtn() {
    document.getElementById("upld-btn").textContent = "Flip";
    document.getElementById("upld-btn").disabled = false;
    document.getElementById("upld-btn").style.backgroundColor = "#3CC3F2";
  }

  // to change the state of btn when clicked once
  function disableBtn() {
    document.getElementById("upld-btn").textContent = "Flip";
    document.getElementById("upld-btn").disabled = true;
    document.getElementById("upld-btn").style.backgroundColor = "grey";
  }

  function handleUpload(e) {
    e.preventDefault();

    if (!file_info) {
      toast.error("No files added", { id: "file-check" });
    }

    let fileFullName = file_info[0].path;
    const extension = fileFullName.split(".").pop();
    //form inputs validation

    if (!(extension === "pptx" || extension === "docx")) {
      toast.error("Only PPTX & DOCX File Accepted.", { id: "file-check" });
      return;
    }

    if (file_info[0]) {
      disableBtn();
      let formData = new FormData();
      formData.append("file", file_info[0]);
      dispatch(setFileText("Waiting to Upload..."));
      dispatch(uploadFile(formData)).then((res) => {
        if (!res.payload.success) {
          activeBtn();
        }
      });

      // axios({
      //   method: "post",
      //   url: `${process.env.REACT_APP_API_URL}api/ezflip/v1/upload-file/`,
      //   data: formData,
      //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      // })
      //   .then((res) => {
      //     if (res.data.success) {
      //       setFileText("Waiting to Upload...");
      //       let data = {
      //         assignment_id: res.data.data.assignment_id,
      //       };

      //       postAuthData(`${process.env.REACT_APP_API_URL}api/ezflip/v1/flip/`, data).then((res) => {
      //         if (res.success === true) {
      //           localStorage.setItem("assignment_id", res.data.assignment_id);
      //           statusAPI();
      //         } else {
      //           alert(res.message);
      //           window.location.reload();
      //         }
      //       });
      //     } else {
      //       alert("Error while upload");
      //     }
      //   })
      //   .catch((err) => {
      //     alert(err.response.data.message);
      //     window.location.reload();
      //   });
    }

    if (localStorage.getItem("token") === null) {
      window.location.reload();
    }
  }

  // useEffect(() => {
  //   if (!dwlnFile) return;
  // }, []);

  return (
    <div className="flip-upload-container container-layout">
      <div className="flip-upload-section">
        <NavLink className="flip-upload-history" to="/ez_flip/history">
          HISTORY
        </NavLink>
        <div className="flip-upload-form-container">
          <div className="drag-n-drop-upload">
            <DragnDropUpload />
            {file_info && file_info[0] && <ImCancelCircle className="cancel-icon" onClick={handleCancel} />}
          </div>
          <div className="flip-upload-form">
            <div className="top-container">
              <div className="flip-file-info-container">
                <div className="flip-file-info-heading">Flipped File</div>
                <div className="flip-file-info-value">
                  {flip_data.file_text && flip_data.file_text}
                  {flip_data.flip_status === "In-Progress" && "Waiting to Upload..."}
                  {flip_data.flip_status === "Done" && (
                    <>
                      <a href={`${flip_data.dwnld_link}`}>Download</a>
                    </>
                  )}
                </div>
              </div>
              <div className="form-sbmt-btn">
                <button
                  onClick={(e) => handleUpload(e)}
                  disabled={flip_data.flip_status === "Done"}
                  value="Upload File"
                  className={flip_data.flip_status === "Done" ? "submit-btn-disabled" : "sbmt-btn"}
                  id="upld-btn"
                >
                  Flip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlipUpload;
