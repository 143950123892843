export const initialState = {
  history_data: {
    data: null,
    loader: null,
    error: null,
  },

  flip_data: {
    file_text: null,
    flip_status: null,
    dwnld_link: null,
  },

  flipCurPage: null,
  totalPage: null,
  loading: null,
  error: null,
  success: null,
};
