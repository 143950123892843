import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTBFilesArrDomain,
  addTBFilesArrFile,
  addTBFilesArrItem,
  addTMFilesArrDomain,
  addTMFilesArrFile,
  addTMFilesArrItem,
  createPostTermBaseUploadData,
  postTermBaseFileUpload,
  resetEditorData,
} from "../../../store/features/ezEditor/ezEditorSlice";
import TMTBUploadSection from "../TMTBUploadSection/TMTBUploadSection";
import "./EditorUserTMUpload.scss";
import toast from "react-hot-toast";
import { handleExistingFileCheck } from "../../../utils/functions/TMTBUploadFileCheckFunctions";
import SelectInputComp from "../../../utils/Components/InputComps/DropdownSelectInput/SelectInputComp";
import {
  addWorkspaceResourceOption,
  getWorkspaceUserOptions,
  resetEditorUserTMData,
} from "../../../store/features/ezEditorUserTM/ezEditorUserTMSlice";

const EditorUserTMUpload = () => {
  const dispatch = useDispatch();
  const { user_tm_data } = useSelector((state) => state.editorData);
  const { user_data } = useSelector((state) => state.editorUserTMData);

  const TMFiles = user_tm_data.tm_data.files_data.files;
  const TBFiles = user_tm_data.tb_data.files_data.files;

  const newTMFiles = user_tm_data.tm_data.files_data.new_tm_files;
  const newTBFiles = user_tm_data.tb_data.files_data.new_tb_files;

  const oldTMFiles = user_tm_data.tm_data.files_data.old_tm_files;
  const oldTBFiles = user_tm_data.tb_data.files_data.old_tb_files;

  const existingTMFiles = user_tm_data.tm_data.files_data.files_name.existing_files;
  const existingTBFiles = user_tm_data.tb_data.files_data.files_name.existing_files;

  const TMFilesArrLastEle = user_tm_data.tm_data.files_data.files[user_tm_data.tm_data.files_data.files.length - 1];
  const TBFilesArrLastEle = user_tm_data.tb_data.files_data.files[user_tm_data.tb_data.files_data.files.length - 1];

  let isTMData = Object.keys(TMFiles[0].file).length > 0;
  let isTBData = Object.keys(TBFiles[0].file).length > 0;

  const validateTMData = (tm_files) => {
    let isTMDataValid = false;
    tm_files.forEach((obj) => {
      const isFileEmpty = Object.keys(obj.file).length > 0;
      if (isFileEmpty) {
        isTMDataValid = true;
      } else {
        isTMDataValid = false;
      }
    });
    return isTMDataValid;
  };

  const validateTBData = (tb_files) => {
    let isTBDataValid = false;

    tb_files.forEach((obj) => {
      const isFileEmpty = Object.keys(obj.file).length > 0;

      if (isFileEmpty) {
        isTBDataValid = true;
      } else {
        isTBDataValid = false;
      }
    });
    return isTBDataValid;
  };

  const handleUploadClick = () => {
    let validTMData = validateTMData(TMFiles); // if true dispatch else error
    let validTBData = validateTBData(TBFiles); // if true dispatch else error

    if (isTMData && isTBData) {
      if (validTMData && validTBData) {
        dispatch(createPostTermBaseUploadData({ key: "resource_id" })).then((res) => {
          if (res) dispatch(postTermBaseFileUpload({ type: "resource" }));
        });
      } else if (validTMData && !validTBData) {
        toast.error("Please select TB file(s)", { id: "upload-file" });
      } else if (validTBData && !validTMData) {
        toast.error("Please select TM file(s)", { id: "upload-file" });
      } else {
        toast.error("Please select TM/TB file(s)", { id: "upload-file" });
      }
    } else if (isTMData || isTBData) {
      if (validTMData && !isTBData) {
        dispatch(createPostTermBaseUploadData({ key: "resource_id" })).then((res) => {
          if (res) dispatch(postTermBaseFileUpload({ type: "resource" }));
        });
      } else if (validTMData && isTBData && !validTBData) {
        toast.error("Please select TB file(s)", { id: "upload-file" });
      } else if (!validTMData && !isTBData) {
        toast.error("Please select TM file(s)", { id: "upload-file" });
      }
      if (validTBData && !isTMData) {
        dispatch(createPostTermBaseUploadData({ key: "resource_id" })).then((res) => {
          if (res) dispatch(postTermBaseFileUpload({ type: "resource" }));
        });
      } else if (validTBData && isTMData && !validTMData) {
        toast.error("Please select TM file(s)", { id: "upload-file" });
      } else if (!validTBData && !isTMData) {
        toast.error("Please select TB file(s)", { id: "upload-file" });
      }
    } else {
      toast.error("Please select TM/TB file(s)", { id: "upload-file" });
    }
  };

  // function debounce(callback, delay) {
  //   let timer;
  //   return function (...args) {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => callback(...args), delay);
  //   };
  // }

  // const debouncedDispatch = debounce((value) => {
  //   dispatch(addClientDetailsText(value));
  // }, 5000);

  const handleTMFileArr = () => {
    const isDisabled = !user_data.resource_id;
    if (isDisabled) {
      toast.error("Please select Workspace Email ID", {
        id: "upload-file",
      });
    } else {
      if (user_tm_data.tm_data.files_data.files.length < 10) {
        if (Object.keys(TMFilesArrLastEle.file).length > 0) {
          dispatch(addTMFilesArrItem());
        } else {
          toast.error("Please add TM file(s)", {
            id: "upload-file",
          });
        }
        //this commented code can be use later
        // if (Object.keys(TMFilesArrLastEle.file).length > 0 && TMFilesArrLastEle.domain !== null) {
        //   dispatch(addTMFilesArrItem());
        // } else {
        //   toast.error("Please add TM file(s)", {
        //     id: "upload-file",
        //   });
        // }
      } else {
        toast.error("Can't add more than 10 TM files", {
          id: "upload-file",
        });
      }
    }
  };

  const handleTBFileArr = () => {
    const isDisabled = !user_data.resource_id;
    if (isDisabled) {
      toast.error("Please select Workspace Email ID", {
        id: "upload-file",
      });
    } else {
      if (user_tm_data.tb_data.files_data.files.length < 10) {
        if (Object.keys(TBFilesArrLastEle.file).length > 0) {
          dispatch(addTBFilesArrItem());
        } else {
          toast.error("Please add TB file(s)", {
            id: "upload-file",
          });
        }
        //this commented code can be use later
        // if (Object.keys(TBFilesArrLastEle.file).length > 0 && TBFilesArrLastEle.domain !== null) {
        //   dispatch(addTBFilesArrItem());
        // } else {
        //   toast.error("Please add TB file(s)", {
        //     id: "upload-file",
        //   });
        // }
      } else {
        toast.error("Can't add more than 10 TB files", {
          id: "upload-file",
        });
      }
    }
  };

  const handleTMFileItem = (e, idx) => {
    const acceptedFormats = ["csv", "tmx", "mxliff", "xliff", "mqxlz"];
    const existingFiles = existingTMFiles.concat(
      newTMFiles?.map((item) => item?.name),
      oldTMFiles?.map((o) => o?.file_name),
    );
    const files = handleExistingFileCheck(e, acceptedFormats, existingFiles);

    dispatch(addTMFilesArrFile({ item_id: idx, value: files }));
  };

  const handlePastTMFile = (option, idx) => {
    let flag = false;
    newTMFiles?.forEach((item) => {
      if (item?.name == option.value[0].name) {
        toast.error(`${item?.name} already exists. Please choose another file.`);
        flag = true;
      }
    });
    if (flag) {
      return;
    }
    dispatch(addTMFilesArrFile({ item_id: idx, value: option.value }));
  };

  const handleTBFileItem = (e, idx) => {
    const acceptedFormats = ["csv", "xlsx"];
    const existingFiles = existingTBFiles.concat(
      newTBFiles?.map((o) => o?.name),
      oldTBFiles?.map((o) => o?.file_name),
    );

    const files = handleExistingFileCheck(e, acceptedFormats, existingFiles);
    dispatch(addTBFilesArrFile({ item_id: idx, value: files }));
  };

  const handlePastTBFile = (option, idx) => {
    let flag = false;
    newTBFiles?.forEach((item) => {
      if (item?.name == option.value[0].name) {
        toast.error(`${item?.name} already exists. Please choose another file.`);
        flag = true;
      }
    });
    if (flag) {
      return;
    }
    dispatch(addTMFilesArrFile({ item_id: idx, value: option.value }));
  };

  const handleTMFileDomain = (option, idx) => {
    if (newTMFiles.length > 0) {
      dispatch(addTMFilesArrDomain({ item_id: idx, value: option }));
    } else {
      toast.error("Please select file", { id: "upload-file" });
    }
  };

  const handleTBFileDomain = (option, idx) => {
    if (newTBFiles.length > 0) {
      dispatch(addTBFilesArrDomain({ item_id: idx, value: option }));
    } else {
      toast.error("Please select file", { id: "upload-file" });
    }
  };

  useEffect(() => {
    dispatch(getWorkspaceUserOptions({}));

    return () => {
      dispatch(resetEditorData());
      dispatch(resetEditorUserTMData());
    };
  }, []);

  useEffect(() => {
    if (!user_tm_data.assignment_id) {
      // clientTextRef.current.value = null;
    }
  }, [!user_tm_data.assignment_id]);

  return (
    <>
      <div className="editor-upload-section-container">
        <section className="editor-upload-section">
          <div className="editor-upload-top-container">
            <div className="editor-upload-assign-selection-container">
              <div className="editor-user-upload-assign-selection-container-dropdown">
                <SelectInputComp
                  className="select-container"
                  label={"Workspace Email ID"}
                  selectedValue={user_data.resource_id}
                  options={user_data.workspace_user_list.data}
                  handleChange={(option) => {
                    dispatch(addWorkspaceResourceOption(option));
                  }}
                  placeholder={"Select Email ID"}
                  clearInput={true}
                />
              </div>
            </div>
            <div className="editor-upload-bottom-container">
              <TMTBUploadSection
                heading={"Add Translation Memory"}
                fileType={"TM"}
                handleFileArr={handleTMFileArr}
                handleFileItem={handleTMFileItem}
                handleFileDomain={handleTMFileDomain}
                handlePastFile={handlePastTMFile}
                filesArr={TMFiles}
                newTMFiles={newTMFiles}
                assignmentID={user_data.resource_id}
                hoverTipText={"Files allowed : .csv, .tmx, .mxliff, .xliff, .mqxlz"}
                acceptedFiles={".csv, .tmx, .mxliff, .xliff, .mqxlz"}
                inputId="upload-tm"
              />
              <TMTBUploadSection
                heading={"Add Term Base"}
                fileType={"TB"}
                handleFileArr={handleTBFileArr}
                handleFileItem={handleTBFileItem}
                handleFileDomain={handleTBFileDomain}
                handlePastFile={handlePastTBFile}
                filesArr={TBFiles}
                newTBFiles={newTBFiles}
                assignmentID={user_data.resource_id}
                hoverTipText={"Files allowed : .csv, .xlsx"}
                acceptedFiles={".csv, .xlsx"}
                inputId="upload-tb"
              />
            </div>
            <div className="editor-upload-submit-btn">
              <button
                onClick={handleUploadClick}
                value="Update Assignment"
                className="sbmt-btn"
                id="upld-btn"
                disabled={!user_data.resource_id || user_tm_data.loading}
              >
                {user_tm_data.loading ? "Updating Assignment... " : "Update Assignment"}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default EditorUserTMUpload;
