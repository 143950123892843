import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
// import { useDispatch } from "react-redux";
import { getAuthData, postAuthData, putAuthData } from "../../../../services/request";
import { resetHistoryData } from "../fetchHistorySlice/fetchHistorySlice";
import { resetFileInfoData } from "../fileContextSlice/fileContextSlice";
import { resetFilterData } from "../filterDataSlice/filterDataSlice";
import { resetWordCountData } from "../wordCountDataSlice/wordCountDataSlice";

import { initialState } from "./common";

export const resetStateData = createAsyncThunk("search-form-data/resetStateData", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(resetSearchFormData());
    thunkAPI.dispatch(resetFileInfoData());
    thunkAPI.dispatch(resetFilterData());
    thunkAPI.dispatch(resetWordCountData());
    thunkAPI.dispatch(resetHistoryData());
    // localStorage.clear();
    localStorage.removeItem("assignment_id");
    localStorage.removeItem("search_type");
    localStorage.removeItem("phrase_list");
    localStorage.removeItem("is_download_files_include");
    localStorage.removeItem("is_word_count_include");
    localStorage.removeItem("search_url");
  } catch (error) {
    toast.error("Unable to Reset The Data");
  }
});

export const postUserData = createAsyncThunk("search-form-data/postUserData", async (data) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/search/`;
  try {
    if (data?.url) {
      const res = await postAuthData(url, data);
      if (res?.success) {
        // console.log("#####", res);
        return res?.data;
        // thunkAPI.dispatch(getStatus(res?.data?.assignment_id));
      } else {
        // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
        return toast.error("error here ");
      }
    } else if (localStorage.getItem("assignment_id")) {
      return {
        assignment_id: localStorage.getItem("assignment_id"),
        search_url: localStorage.getItem("url"),
        search_type: localStorage.getItem("search_type"),
        phrase_list: localStorage.getItem("phrase_list"),
        is_download_files_include: JSON.parse(localStorage.getItem("is_download_files_include")),
        is_word_count_include: JSON.parse(localStorage.getItem("is_word_count_include")),
        is_file_include: JSON.parse(localStorage.getItem("is_file_include")),
      };
    }
  } catch (err) {
    toast.error(`Invalid User Detail`);
    return `Invalid User Details`;
  }
});

/*  Fetching Status from Backend to display data or not  */

export const getStatus = createAsyncThunk("search-form-data/getStatus", async (ASSIGNMENTID) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/status/${ASSIGNMENTID}/`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      return res;
    } else {
      return toast.error("Can't fetch data");
      // return;
    }
  } catch (err) {
    return console.error(err);
  }
});

// +++++++++ OnClick Links Redirect Data api  +++++++++++++
export const fetchAssignmentData = createAsyncThunk("search-form-data/fetchAssignmentData", async (assId) => {
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/search/${assId}/`;
  try {
    const res = await getAuthData(url);
    if (res.success) {
      // console.log(res.data);
      return res?.data;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      toast.error("error here ");
    }
  } catch (err) {
    console.error(`Failed to get Assignment Data`);
  }
});

export const updatePhraseData = createAsyncThunk("search-form-data/updatePhraseData", async (args) => {
  // console.log(args);
  let url = `${process.env.REACT_APP_API_URL}/api/ezsearch/v1/phrase/${args.assId}/`;
  let data = {
    search_phrase_list: args.search_phrase_list,
  };
  try {
    const res = await putAuthData(url, data);
    if (res.success) {
      return res;
    } else {
      // return thunkAPI.rejectWithValue({ idx: id, err: res.message });
      toast.error("Invalid Search Phrase");
    }
  } catch (err) {
    // toast.error(`Failed To Get Unsubmitted Data!`);
    toast.error("Invalid Search Phrase");
  }
});

const searchFormDataSlice = createSlice({
  name: "search-form-data",
  initialState,
  reducers: {
    setSearchType: (state, { payload }) => {
      state.search_form_data.search_type = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.active_tab = payload;
    },
    handleSearchPhrases: (state, { payload }) => {
      // console.log(payload);
      state.phrase_list = payload;
    },
    handleSearchMainAccess: (state, { payload }) => {
      state.search_form_page_access = payload;
    },
    resetSearchFormData: () => {
      return initialState;
    },
    setActiveMenu: (state, { payload }) => {
      state.active_menu = payload;
      localStorage.setItem("active_menu", payload || "count");
    },
    setSearchPhrasesInclude: (state, { payload }) => {
      state.search_form_data.is_search_phrase_include = payload;
    },
    setFileInclude: (state, { payload }) => {
      state.search_form_data.is_file_include = payload;
    },
    setWordCountInclude: (state, { payload }) => {
      state.search_form_data.is_word_count_include = payload;
    },
  },
  extraReducers: {
    [postUserData.pending]: (state) => {
      state.loader = true;
    },
    [postUserData.fulfilled]: (state, { payload }) => {
      if (!payload) return;
      // console.log(payload.phrase_list);
      // console.log(state.search_form_data.is_file_include);
      // if (state.search_form_data.is_file_include) {
      // }

      state.search_form_data.url = payload.search_url;
      state.search_form_data.assignment_id = payload.assignment_id;
      state.search_form_data.search_type = payload.search_type;
      state.search_form_data.phrase_list = payload.phrase_list;
      state.search_form_data.is_download_files_include = payload.is_download_files_include;
      state.search_form_data.is_word_count_include = payload.is_word_count_include;

      localStorage.setItem("assignment_id", payload.assignment_id);
      // localStorage.setItem("url", payload.search_url);
      // localStorage.setItem("search_type", payload.search_type);
      // localStorage.setItem("phrase_list", payload.phrase_list);
      // localStorage.setItem("is_download_files_include", payload.is_download_files_include);
      // localStorage.setItem("is_word_count_include", payload.is_word_count_include);
      // localStorage.setItem("is_file_include", true);
    },
    [postUserData.rejected]: (state, { payload }) => {
      state.loader = false;
      state.error = payload;
    },
    [getStatus.pending]: (state) => {
      state.fetch_status.loader = true;
    },
    [getStatus.fulfilled]: (state, { payload }) => {
      state.fetch_status.loader = false;
      // console.log(payload);
      state.fetch_status.search_status = payload.search_status;
      state.fetch_status.update_status = payload.update_status;
    },
    [getStatus.rejected]: (state, { payload }) => {
      state.fetch_status.loader = false;
      state.fetch_status.error = payload;
    },
    [fetchAssignmentData.pending]: (state) => {
      state.loader = true;
    },
    [fetchAssignmentData.fulfilled]: (state, { payload }) => {
      if (!payload) return;
      // console.log(payload.assignment_id);
      state.search_form_data.url = payload.search_url;
      state.search_form_data.assignment_id = payload.assignment_id;
      state.search_form_data.search_type = payload.search_type;
      state.search_form_data.phrase_list = payload.phrase_list;
      state.search_form_data.is_download_files_include = payload.is_download_files_include;
      state.search_form_data.is_word_count_include = payload.is_word_count_include;

      // localStorage.setItem("assignment_id", payload.data.assignment_id);
      localStorage.setItem("assignment_id", payload.assignment_id);
      localStorage.setItem("url", payload.search_url);
      localStorage.setItem("search_type", payload.search_type);
      localStorage.setItem("phrase_list", payload.phrase_list);
      localStorage.setItem("is_download_files_include", payload.is_download_files_include);
      localStorage.setItem("is_word_count_include", payload.is_word_count_include);
    },
    [fetchAssignmentData.rejected]: (state, { payload }) => {
      state.loader = false;
      state.error = payload;
    },
    [updatePhraseData.pending]: (state) => {
      state.search_form_data.loader = true;
    },
    [updatePhraseData.fulfilled]: (state, { payload }) => {
      state.search_form_data.loader = false;
      // console.log(Object.keys(payload.data));
      state.search_form_data.phrase_list = Object.keys(payload.data);
    },
    [updatePhraseData.rejected]: (state, { payload }) => {
      state.search_form_data.loader = false;
      state.search_form_data.error = payload;
    },
  },
});

export const {
  setSearchType,
  setActiveTab,
  setTabActive,
  resetSearchFormData,
  handleSearchMainAccess,
  setSearchPhrases,
  handleSearchPhrases,
  setActiveMenu,
  setFileInclude,
  setWordCountInclude,
  setSearchPhrasesInclude,
} = searchFormDataSlice.actions;

export default searchFormDataSlice.reducer;
