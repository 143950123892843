import React, { useEffect } from "react";
import Rows from "./CountRows";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./CountHistory.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCountHistoryData, setCurCountPage } from "../../../store/features/ezCountSlice/ezCountSlice";

const CountHistory = () => {
  const dispatch = useDispatch();
  const { curCountPage, count_history_data, totalCountPage } = useSelector((state) => state.countData);

  useEffect(() => {
    if (curCountPage) {
      dispatch(getCountHistoryData(curCountPage));
    }
  }, [curCountPage]);

  useEffect(() => {
    dispatch(setCurCountPage(1));
  }, []);

  const handlePage = (pageVal) => {
    dispatch(setCurCountPage(curCountPage + pageVal));
  };

  return (
    <>
      <div className="count_history_container">
        <div className="count-history-heading">HISTORY</div>
        <div className="count-history-content">
          <div className="count_history_content_inner">
            <div className="count_history_content_heading">
              <div className="count_history_content_heading_head">
                <div className="table_heading">Date</div>
                <div className="table_heading">File Name</div>
                <div className="table_heading">Source Language</div>
                <div className="table_heading">Total Word Count</div>
                <div className="table_heading">File</div>
              </div>

              {count_history_data?.data?.data?.map((rowData) => (
                <Rows
                  key={rowData?.assignment_id}
                  assgn_Id={rowData?.assignment_id}
                  date={rowData?.created_at}
                  file_name={rowData?.source_file.file_name}
                  src_lang={rowData?.source_language}
                  total_file_wc={rowData?.total_word_count}
                  file_dwnld_link={rowData?.source_file?.file_link}
                />
              ))}
            </div>
            {count_history_data?.data && (
              <div className="pagination">
                <button
                  type="prev"
                  id="prev-btn"
                  onClick={() => handlePage(-1)}
                  disabled={curCountPage === 1 ? true : false}
                  style={curCountPage === 1 ? { color: "grey" } : {}}
                >
                  <FaAngleLeft />
                  Previous
                </button>
                <span>
                  {curCountPage} of {totalCountPage}
                </span>
                <button
                  type="next"
                  id="next-btn"
                  onClick={() => handlePage(1)}
                  disabled={curCountPage === totalCountPage ? true : false}
                  style={curCountPage === totalCountPage ? { color: "grey" } : {}}
                >
                  Next
                  <FaAngleRight />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CountHistory;
