import "./SearchInfoComp.scss";
import { IoIosArrowBack } from "react-icons/io";
import SearchWordCountTab from "./components/SearchWordCountTab/SearchWordCountTab";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadTab from "./components/FileDownloadTab/FileDownloadTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatus,
  // postUserData,
  // handleSearchMainAccess,
  resetStateData,
  setActiveTab,
  setFileInclude,
  setActiveMenu,
  fetchAssignmentData,
  // fetchAssignmentData,
} from "../../../store/features/ezSearchSlices/searchFormDataSlice/searchFormDataSlice";
import { ACTIVE_MENU, ACTIVE_STATUS, ACTIVE_TAB } from "../../../utils/namespaces/ACTIVE_TAB";
import { useEffect, useState } from "react";
import { getExcelFile } from "../../../store/features/ezSearchSlices/wordCountDataSlice/wordCountDataSlice";
import { downloadFile } from "../../../utils/functions/downloadFile";
import { toast } from "react-hot-toast";
import { resetutilData } from "../../../store/features/utilSlice/utilSlice";
import { resetUserTab } from "../../../store/features/ezCountSlice/ezCountSlice";

const SearchInfoComp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState(null);
  // const { is_word_count_include, is_download_files_include, url, search_type, assignment_id } = useSelector(
  //   (state) => state.searchFormData,
  // );
  const { assignment_id } = useParams();
  const { search_form_data } = useSelector((state) => state.searchFormData);
  const { active_tab, fetch_status, search_form_page_access } = useSelector((state) => state.searchFormData);
  // const { word_count_data } = useSelector((state) => state.wordCountData);

  const goBack = () => {
    // clearing all the state here
    dispatch(resetStateData())
      .then((res) => {
        if (res?.error) {
          throw res.payload;
        } else {
          navigate("/ez_search");
          dispatch(setActiveMenu(ACTIVE_MENU.SEARCH));
          resetutilData();
          resetUserTab();
        }
      })
      .catch((err) => console.error(err?.err || err || "Error while reseting data"));
  };

  const toggleTab = (index) => {
    dispatch(setActiveTab(index)); //index name should be TAB_NAME
  };

  function downloadXLSFile() {
    dispatch(getExcelFile(localStorage.getItem("assignment_id")))
      .then((res) => {
        // console.log(res);
        if (!res.payload.download_link) {
          throw res.payload;
        } else {
          // console.log("Downloading file...", download_link);
          downloadFile(res.payload.download_link);
        }
      })
      .catch((err) => console.error(err || "Error while Downloading Excel File"));
  }

  useEffect(() => {
    const myInterval = setInterval(() => {
      // console.log("Current status:", fetch_status.search_status);

      if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_ERROR) {
        toast.error("No data Found on given URL");
        clearInterval(myInterval);
        // return;
      }

      if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_STARTED) {
        dispatch(getStatus(localStorage.getItem("assignment_id")));
      } else if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED) {
        clearInterval(myInterval);
      } else if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL) {
        clearInterval(myInterval);
      }
    }, 10 * 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [fetch_status]);

  // useEffect(() => {
  //   if (!word_count_data.data) {
  //     navigate("/ez_search");
  //   }
  // }, []);

  // useEffect(() => {
  //   const myInterval = setInterval(() => {
  //     if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_STARTED) {
  //       dispatch(getStatus(localStorage.getItem("assignment_id")));
  //     } else if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED) {
  //       clearInterval(myInterval);
  //     } else if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL) {
  //       clearInterval(myInterval);
  //     } else if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_ERROR) {
  //       toast.error(`No data Found on given URL`);
  //       clearInterval(myInterval);
  //     }
  //   }, 10 * 1000);

  //   return () => clearInterval(myInterval);
  // }, [fetch_status.search_status]);

  // useEffect(() => {
  //   if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_ERROR) {
  //     toast.error(`No data Found on given URL`);
  //   }
  // }, [fetch_status.search_status]);

  // to detect page reload //
  // useEffect(() => {
  //   if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) {
  //     //     console.log("Page reloaded");
  //   }
  // }, []);

  useEffect(() => {
    // get assignemntid from url
    // dispatch(getStatus(localStorage.getItem("assignment_id")));
    // dispatch(fetchAssignmentData(localStorage.getItem("assignment_id")));

    // dispatch(postUserData());

    // if (!search_form_page_access) dispatch(handleSearchMainAccess(false));
    // if (search_form_page_access) {
    //   dispatch(postUserData());
    // }
    if (search_form_data.search_type === "page") {
      setSearchType("This Link");
    } else if (search_form_data.search_type === "entire") {
      setSearchType("Entire Website");
    } else {
      setSearchType("All Links Starting with Above URL");
    }
  }, []);

  useEffect(() => {
    if (search_form_page_access && !search_form_data.assignment_id) {
      // dispatch(postUserData());
      if (!search_form_data.is_file_include) {
        const isFileInclude = localStorage.getItem("is_file_include");
        if (isFileInclude) {
          dispatch(setFileInclude(isFileInclude));
        }
      }
    }
  }, [search_form_page_access]);

  useEffect(() => {
    if (search_form_data.assignment_id || search_form_data.url) {
      if (search_form_data.is_word_count_include && search_form_data.is_download_files_include) {
        dispatch(setActiveTab(ACTIVE_TAB.WORD_COUNT_TAB));
      } else if (search_form_data.is_word_count_include) {
        dispatch(setActiveTab(ACTIVE_TAB.WORD_COUNT_TAB));
      } else if (search_form_data.is_download_files_include) {
        dispatch(setActiveTab(ACTIVE_TAB.FILE_INFO_TAB));
      }
    }
  }, [search_form_data.assignment_id, search_form_data.url]);

  useEffect(() => {
    if (assignment_id) {
      localStorage.setItem("assignment_id", assignment_id);
    }
  }, []);

  useEffect(() => {
    dispatch(getStatus(assignment_id ? assignment_id : localStorage.getItem("assignment_id")));
  }, [assignment_id]);

  useEffect(() => {
    if (fetch_status?.search_status === ACTIVE_STATUS.SEARCH_COMPLETED || fetch_status?.search_status === ACTIVE_STATUS.SEARCH_PARTIAL) {
      dispatch(fetchAssignmentData(assignment_id));
    }
  }, [fetch_status?.search_status, assignment_id]);

  return (
    <section id="word-count-container">
      <div className="word-count-box container-box">
        <div className="word-count-url-container">
          <div className="go-back-context" onClick={goBack}>
            <IoIosArrowBack className="go-back-icon" />
          </div>
          <div className="word-count-info-header">Website URL :</div>
          <div className="word-count-info-title">{search_form_data.url}</div>
        </div>
        <div className="search-tag-container">
          <div className="word-count-info-header">Search :</div>
          <div className="word-count-info-title">{searchType}</div>
        </div>
        <div className="word-count-tab-button-container">
          <div className="word-count-tab-container">
            <div className={active_tab === ACTIVE_TAB.WORD_COUNT_TAB && "active-tab"} onClick={() => toggleTab(ACTIVE_TAB.WORD_COUNT_TAB)}>
              {search_form_data.is_word_count_include && "Word Count & Search"}
            </div>
            <div className={active_tab === ACTIVE_TAB.FILE_INFO_TAB && "active-tab"} onClick={() => toggleTab(ACTIVE_TAB.FILE_INFO_TAB)}>
              {search_form_data.is_download_files_include && "Search & Download Files"}
            </div>
          </div>
          <div className="word-tab-container">
            <button className="tool-dwnload-btn" onClick={() => downloadXLSFile()}>
              Get Excel
            </button>
          </div>
        </div>
        <SearchWordCountTab />
        <FileDownloadTab />
      </div>
    </section>
  );
};

export default SearchInfoComp;
