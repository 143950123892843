const FileIcon = ({ filename }) => {
  let url;
  if (!filename) {
    url = "/resources/file_type_icons/file.svg";
  } else {
    // switch (filename?.split(".").at(-1).toLowerCase()) {
    switch (filename?.split(".").pop().toLowerCase()) {
      case "docx":
        url = "/resources/file_type_icons/word.svg";
        break;
      case "pptx":
        url = "/resources/file_type_icons/powerpoint.svg";
        break;
      case "xlsx":
        url = "/resources/file_type_icons/excel.svg";
        break;
      default:
        url = "/resources/file_type_icons/file.svg";
        break;
    }
  }
  return (
    <div className="file-icon">
      <img src={process.env.PUBLIC_URL + url} alt="File" className="file-icon-size" />
      {/* {type && <span className="ops-file-type">{type}</span>} */}
    </div>
  );
};

export default FileIcon;
