import { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterWordCountData,
  handleWordCountFilterModal,
} from "../../../../../store/features/ezSearchSlices/filterDataSlice/filterDataSlice";
import { changeWCPage, getWordCountData } from "../../../../../store/features/ezSearchSlices/wordCountDataSlice/wordCountDataSlice";
import "./WordCountFilter.scss";

const WordCountFilter = () => {
  const dispatch = useDispatch();
  const { word_count_filter_data } = useSelector((state) => state.filterData);
  // const [updatePhraseList, setUpdatePhraseList] = useState([]);
  const { word_count_data, search_phrase_data } = useSelector((state) => state.wordCountData);
  const [updateWCFileList, setUpdateWCFileList] = useState([]);

  function filterData(phraseList) {
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: 1,
      search_phrase_data: phraseList,
      search_link_data: [],
    };
    dispatch(getWordCountData(data));
    dispatch(changeWCPage(1));
    dispatch(handleWordCountFilterModal(false));
  }

  // function selectCheckBox(e) {
  //   if (e.target.checked) {
  //     // console.log(e.target.name);
  //     let phrase = e.target.name;
  //     setUpdatePhraseList((current) => [...current, phrase]);
  //   } else {
  //     updatePhraseList.pop();
  //   }
  // }

  function selectCheckBox(e) {
    // if (e.target.checked) {
    if (!updateWCFileList.includes(e.target.name)) {
      if (e.target.checked) {
        // console.log("checked", e.target.name);
        setUpdateWCFileList((preVal) => [...preVal, e.target.name]);
        // console.log("new list:", updateWCFileList);
      }
      // }
    } else {
      // console.log("unchecked", e.target.name);
      // console.log(updateFileList, updateFileList.indexOf(e.target.name));
      const splice_idx = updateWCFileList.indexOf(e.target.name);
      const new_updated_file_list = [...updateWCFileList];
      new_updated_file_list.splice(splice_idx, 1);
      setUpdateWCFileList(new_updated_file_list);
      // console.log("old list:", updateWCFileList);
      // console.log(updateFileList);
    }
  }

  // useEffect(() => {
  //   console.log(updateWCFileList);
  // }, [updateWCFileList]);

  useEffect(() => {
    if (search_phrase_data) {
      setUpdateWCFileList(search_phrase_data);
    }
  }, [search_phrase_data]);

  useEffect(() => {}, [word_count_data]);

  function handleSelectionAll() {
    let data = word_count_filter_data?.data?.data;
    let actualData = Object.keys(data);
    setUpdateWCFileList(actualData);
  }

  function handleSelectionNone() {
    setUpdateWCFileList([]);
  }

  useEffect(() => {
    let assId = localStorage.getItem("assignment_id");
    dispatch(getFilterWordCountData(assId));
  }, []);

  return (
    <section id="word-count-filter-section">
      <div className="word-count-filter-header">
        <span className="word-count-filter-title">
          <FaFilter className="word-count-filter" />
          Filter Search
        </span>
        <span className="word-count-cross" onClick={() => dispatch(handleWordCountFilterModal(false))}>
          <RxCross2 />
        </span>
      </div>
      <div className="word-count-select-option">
        <span onClick={handleSelectionAll}>Select All</span>
        <span onClick={handleSelectionNone}>Select None</span>
      </div>

      {word_count_filter_data?.loader === true ? (
        <span className="loading-style">Loading..</span>
      ) : (
        <>
          <div className="word-count-phrases-checkbox">
            {Object.keys(word_count_filter_data?.data?.data)?.map((phrase, id) => {
              return (
                <div className="phrases-list" key={id}>
                  <input
                    type="checkbox"
                    name={phrase}
                    data-name={phrase}
                    onClick={(e) => selectCheckBox(e)}
                    className="phrase-item filter-box filter-ctrl"
                  />

                  {updateWCFileList?.map((cur) => {
                    return <>{cur === phrase && <img id={`check-${id}`} src="/resources/check.png" className="chck-icon" />} </>;
                  })}
                  <span>
                    {phrase === "#null#" ? "None of the Above" : phrase} ({word_count_filter_data?.data?.data[phrase]} URLs)
                  </span>
                </div>
              );
            })}
          </div>
          <button
            className="filter-btn"
            onClick={() => {
              filterData(updateWCFileList);
            }}
          >
            Apply Filter
          </button>
        </>
      )}
    </section>
  );
};

export default WordCountFilter;
