import React, { useEffect } from "react";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFileContextPage,
  fetchFileContentData,
  searchFileName,
} from "../../../../../store/features/ezSearchSlices/fileContextSlice/fileContextSlice";
import { handleFileTabFilterModal } from "../../../../../store/features/ezSearchSlices/filterDataSlice/filterDataSlice";
import LoaderModal from "../../../../../utils/Components/LoaderModal/LoaderModal";
import ContentShortenComp from "../../../../../utils/functions/ContentShortenComp";
// import downloadFiles from "../../../../../utils/functions/downloadFiles";
import { ACTIVE_STATUS, ACTIVE_TAB } from "../../../../../utils/namespaces/ACTIVE_TAB";
import FileDownloadTabFilter from "../FIleDownloadTabFilter/FileDownloadTabFilter";
import FileInfoPagination from "../FileInfoPagination/FileInfoPagination";
import { toast } from "react-hot-toast";
import "./FileDownloadTab.scss";

const FileDownloadTabInfoData = () => {
  const { file_context_data } = useSelector((state) => state.fileContext);

  const unsecuredCopyToClipboard = (text) => {
    const input = document.createElement("input");
    input.setAttribute("type", "text");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    try {
      if (document.execCommand("copy")) {
        toast.success("Content copied to clipboard");
      } else {
        toast.error("Failed to copy");
      }
    } catch (err) {
      toast.error("Failed to copy");
    }
    document.body.removeChild(input);
  };

  function handleCopyClipboard(url) {
    // console.log(url);
    // toast.success("copied file link", url);
    // navigator.clipboard.writeText(url).then(
    //   () => {
    //     toast.success("Content copied to clipboard");
    //   },
    //   () => {
    //     toast.error("Failed to copy");
    //   },
    // );

    if (window.isSecureContext && navigator.clipboard) {
      // navigator.clipboard.writeText(url);
      try {
        navigator?.clipboard?.writeText(url);
        toast.success("Content copied to clipboard");
      } catch (error) {
        console.error(error);
        toast.error("Failed to copy");
      }
    } else {
      unsecuredCopyToClipboard(url);
    }
  }

  return (
    <>
      <section className="file-info-list">
        {file_context_data?.data?.data?.file_data.map((curVal, id) => {
          return (
            <div className="file-info-list-item" key={id}>
              <div className="file-name">
                <ContentShortenComp content={curVal.file_name} max_length={50} last_chars_length={5} />
              </div>
              <div className="file-type text-data-left">{curVal.file_type.toUpperCase()}</div>
              <div id={id} name={curVal?.file} data-name={curVal?.file_url} className="file-download-link text-data-left">
                <a
                  href={curVal?.file_url}
                  data-name={curVal?.file_url}
                  className="file-dwnld-link"
                  target="_blank"
                  rel="noreferrer"
                  // onClick={() => downloadFiles(curVal?.file_url)}
                >
                  Download
                </a>
              </div>
              <div className="file-copy-link text-data-left">
                <a href="#" id="copy-url" onClick={() => handleCopyClipboard(curVal?.file_url)}>
                  Copy Link
                </a>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

const FileDonwloadTabInfo = () => {
  const dispatch = useDispatch();
  const { file_context_data, file_name, total_files } = useSelector((state) => state.fileContext);
  const { show_file_tab_filter_modal } = useSelector((state) => state.filterData);
  const { active_tab } = useSelector((state) => state.searchFormData);
  const { curFileContextPage } = useSelector((state) => state.fileContext);

  // function downloadAllFiles(entity) {
  //   let ancorTag = document.getElementsByClassName(`${entity}`);
  //   for (let i = 0; i < ancorTag.length; i++) {
  //     console.log(ancorTag[i].dataset.name);

  //     window.open(ancorTag[i].dataset.name);
  //   }
  // }

  // const downloadFiles = (entity) => {
  //   let files = document.getElementsByClassName(entity);
  //   console.log(files);

  //   for (let i = 0; i < files.length; i++) {
  //     const link = document.createElement("a");
  //     link.href = files[i].dataset.name;
  //     link.download = files[i].dataset.name;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  //   // files.forEach((file) => {});
  // };

  function filterFileDataByFileName(file_name) {
    dispatch(handleFileTabFilterModal(false));
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curFileContextPage,
      search_file_type: [],
      search_file_name: file_name,
    };
    dispatch(fetchFileContentData(data));
  }

  return (
    <section className={active_tab === ACTIVE_TAB.FILE_INFO_TAB ? "active-tab" : "file-content-tab-section"}>
      <div className="file-info-input-box">
        <div className="file-info-title">Search {total_files} Files :</div>
        <input type="text" className="file-info-inputbox" onChange={(e) => dispatch(searchFileName(e.target.value))} />
        <button className="search-file-btn spcl-btn" onClick={() => filterFileDataByFileName(file_name)}>
          Search File Names
        </button>
        {/* <button className="spcl-btn" onClick={() => downloadAllFiles("file-dwnld-link")}>
          Download All Files
        </button> */}
      </div>

      <section>
        <div className="file-info-titles">
          <div>File Name</div>
          <div className=" text-data-left">
            File Type
            <button className="filter-icon-btn" onClick={() => dispatch(handleFileTabFilterModal(true))}>
              <FaFilter />
            </button>
          </div>
          {show_file_tab_filter_modal && <FileDownloadTabFilter />}
          <div className=" text-data-left">File</div>
          <div className=" text-data-left">Link to file</div>
        </div>
      </section>
      {file_context_data?.loader === true ? <LoaderModal width={"85vw"} verticleHeight={"48vh"} /> : <FileDownloadTabInfoData />}
      {!file_context_data?.loader && <FileInfoPagination />}
    </section>
  );
};

const FileDownloadTab = () => {
  const dispatch = useDispatch();
  const { curFileContextPage } = useSelector((state) => state.fileContext);

  const { active_tab, fetch_status } = useSelector((state) => state.searchFormData);

  useEffect(() => {
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curFileContextPage,
      search_file_type: [],
      search_file_name: "",
    };
    if (fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED || fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL) {
      if (active_tab === ACTIVE_TAB.FILE_INFO_TAB) dispatch(fetchFileContentData(data));
    }
  }, [fetch_status.search_status, active_tab]);

  useEffect(() => {
    dispatch(changeFileContextPage(1));
  }, []);

  useEffect(() => {
    let data = {
      assId: localStorage.getItem("assignment_id"),
      page: curFileContextPage,
    };

    if (
      (curFileContextPage >= 1 && fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED) ||
      (curFileContextPage >= 1 && fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL)
    )
      dispatch(fetchFileContentData(data));
  }, [curFileContextPage]);

  return (
    <section>
      {fetch_status.search_status === ACTIVE_STATUS.SEARCH_COMPLETED && <FileDonwloadTabInfo />}
      {fetch_status.search_status === ACTIVE_STATUS.SEARCH_PARTIAL && <FileDonwloadTabInfo />}
    </section>
  );
};

export default FileDownloadTab;
