// import { toast } from "react-hot-toast";

// import { makeSecureDecrypt } from "./security";

//  temp changes

// const loginToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzZjc0ZjZmYTk4ZmEyMWU5ZjFlNzMyYyIsImVtYWlsIjoic2h1YmhhbS5yYXZhdEBlei53b3JrcyIsImV4cCI6MTY3NzQ4NTQzN30.ej6H6wpDT-VyAvV01MMhxqPPdfneULjdbyF89oItfho`;

export async function postData(url, data) {
  const response = await fetch(url, {
    method: "POST",
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response.json();
}

export async function getData(url) {
  const response = await fetch(url, {
    method: "GET",
  });

  return response.json();
}

export async function postAuthData(url, data) {
  // if (!login_token && !localStorage.getItem("login_token")) {
  //   // toast.error("Login token not found!");
  //   throw "Login token not found";
  // }
  const user = localStorage.getItem("token");
  // const user = login_token || localStorage.getItem("login_token");

  // const user = loginToken;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status === 403 || response.status === 401) {
    // toast.error("Unauthorized access detected!");
    localStorage.clear();
    // window.location.reload();
  }
  return response.json();
}

export async function getAuthData(url) {
  // if (!login_token && !localStorage.getItem("login_token")) {
  //   // toast.error("Login token not found!");
  //   return;
  // }
  // const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("login_token")));
  const user = localStorage.getItem("token");
  // const user = loginToken;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user}`,
      "Content-Type": "application/json",
    },
  });
  if (response.status === 403 || response.status === 401) {
    // toast.error("Unauthorized access detected!");
    localStorage.clear();
    // window.location.reload();
  }
  return response.json();
}

export async function putAuthData(url, data) {
  // if (!login_token && !localStorage.getItem("login_token")) {
  //   // toast.error("Login token not found!");
  //   return;
  // }
  // const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("login_token")));
  // const user = login_token || localStorage.getItem("login_token");
  const user = localStorage.getItem("token");

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${user}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status === 403 || response.status === 401) {
    // toast.error("Unauthorized access detected!");
    localStorage.clear();
    // window.location.reload();
  }
  return response.json();
}

// export const getDownloadData = async (url) => {
//   if (localStorage.getItem("login_token") === null) {
//     window.location.reload();
//   }
//   // const user = JSON.parse(
//   //   makeSecureDecrypt(localStorage.getItem("login_token")),
//   // );
//   const response = await fetch(url, {
//     method: "GET",
//     headers: {
//       // Authorization: `Bearer ${user}`,
//     },
//   });
//   if (response.status === 403 || response.status === 401) {
//     localStorage.clear();
//     window.location.reload();
//   }
//   return response;
// };

// export async function patchAuthData(url, data, login_token) {
//   if (!login_token && !localStorage.getItem("login_token")) {
//     // toast.error("Login token not found!");
//     throw "Login token not found";
//   }
//   // const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("login_token")));
//   const user = login_token || localStorage.getItem("login_token");
//   const response = await fetch(url, {
//     method: "PATCH",
//     headers: {
//       Authorization: `Bearer ${user}`,
//       "Content-Type": "application/json",
//       role: localStorage.getItem("role"),
//     },
//     body: JSON.stringify(data),
//   });
//   if (response.status === 403 || response.status === 401) {
//     // toast.error("Unauthorized access detected!");
//     // localStorage.clear();
//     // window.location.reload();
//   }
//   return response.json();
// }

// export async function deleteAuthData(url, login_token) {
//   if (!login_token && !localStorage.getItem("login_token")) {
//     // toast.error("Login token not found!");
//     return;
//   }
//   // const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("login_token")));
//   const user = login_token || localStorage.getItem("login_token");

//   const response = await fetch(url, {
//     method: "DELETE",
//     headers: {
//       Authorization: `Bearer ${user}`,
//       role: localStorage.getItem("role"),
//     },
//   });
//   if (response.status === 403 || response.status === 401) {
//     // toast.error("Unauthorized access detected!");
//     // localStorage.clear();
//     // window.location.reload();
//   }
//   return response.json();
// }
