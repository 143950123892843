import { FaInfoCircle, FaPlusSquare, FaUpload } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "./TMTBUploadSection.scss";

// import domain_sub_domain from "../../../data/ez_editor/domain_sub_domain.json";
// import SelectInputComp from "../../../utils/Components/InputComps/DropdownSelectInput/SelectInputComp";

const FileUploadSection = ({
  idx,
  hoverTipText,
  acceptedFiles,
  inputId,
  filesArr,
  handleFileItem,
  // handleFileDomain,
  assignmentID,
  // newTMFiles,
  // newTBFiles,
}) => {
  return (
    <>
      <div className="tmtb-upload-user-container-dropdown-section">
        <div className="tmtb-upload-user-container-dropdown-section-upload">
          <div className="tmtb-upload-user-container-dropdown-section-upload-label-head">
            <div className="label-with-tip">
              <div className="dropdown-label"> Upload File</div>
              <div
                className="show-tip-icon"
                data-tooltip-id="show-hover-tip"
                data-tooltip-content={hoverTipText}
                data-tooltip-place="top-start"
              >
                <FaInfoCircle />
              </div>
            </div>

            <div className="inputContainer">
              <input
                type="file"
                multiple={false}
                accept={acceptedFiles}
                value="" // this is given to clear the input value when the state gets cleared
                style={{ display: "none", pointerEvents: assignmentID ? "auto" : "none" }}
                onChange={(e) => {
                  handleFileItem(e, idx);
                }}
                id={inputId + idx}
                disabled={!assignmentID}
              />
              <div className={`uploadBox ${!assignmentID ? "disabled" : ""}`}>
                <div className="file_section">
                  {Object.keys(filesArr[idx]?.file)?.length === 0 ? (
                    <div className="no-file-chosen">Select file to upload</div>
                  ) : (
                    <>
                      <div className="filename">{filesArr[idx]?.file[0]?.name}</div>
                    </>
                  )}
                </div>
                <div className={`file_upload_button ${!assignmentID ? "disabled" : ""}`}>
                  <label htmlFor={inputId + idx}>
                    <span style={{ color: assignmentID ? "var(--prime-blue)" : "var(--light-grey)" }}>
                      <FaUpload />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="tmtb-upload-user-container-dropdown-section-domain">
          <SelectInputComp
            className="select-container"
            label={"Domain : Sub-domain"}
            selectedValue={filesArr[idx]?.domain}
            options={domain_sub_domain?.domain_sub_domains}
            handleChange={(option) => {
              handleFileDomain(option, idx);
            }}
            placeholder={newTMFiles?.length === 0 || newTBFiles?.length === 0 ? "Please Upload File" : "Select File Domain and Sub-Domain"}
            clearInput={true}
            disabled={!assignmentID || newTMFiles?.length === 0 || newTBFiles?.length === 0}
          />
        </div> */}
      </div>
    </>
  );
};

const TMTBUploadSection = ({
  heading,
  handleFileArr,
  filesArr,
  hoverTipText,
  acceptedFiles,
  inputId,
  handleFileItem,
  handleFileDomain,
  assignmentID,
  newTMFiles,
  newTBFiles,
}) => {
  return (
    <>
      <section className="tmtb-upload-user-section">
        <div className="tmtb-upload-user-container">
          <div className="tmtb-upload-user-container-head">
            <div className="tmtb-upload-user-container-head-heading">{heading}</div>

            <div className="tmtb-upload-user-container-head-add-btn">
              <FaPlusSquare onClick={handleFileArr} />
            </div>
          </div>
          <div className="container-wrapper" id="tmtb-upload-user-container">
            {filesArr.map((item, i) => {
              return (
                <FileUploadSection
                  key={i}
                  idx={i}
                  hoverTipText={hoverTipText}
                  acceptedFiles={acceptedFiles}
                  inputId={inputId}
                  handleFileItem={handleFileItem}
                  handleFileDomain={handleFileDomain}
                  assignmentID={assignmentID}
                  filesArr={filesArr}
                  newTMFiles={newTMFiles}
                  newTBFiles={newTBFiles}
                />
              );
            })}
          </div>
        </div>
        <Tooltip id="show-hover-tip" style={{ backgroundColor: "var(--prime-blue)" }} place="top-start" />
      </section>
    </>
  );
};

export default TMTBUploadSection;
