export const initialState = {
  loading: false,
  error: null,
  part: {
    partLevelInformation: [],
  },
  slide: {
    slidePageLevelInformation: [],
  },
  user_tm_data: {
    workspace_assgn_list: {
      data: [],
      loading: null,
      error: null,
    },
  },
};
