import "./Navbar.scss";
import { Outlet } from "react-router-dom";
import LogoutModal from "./Components/LogoutModal/LogoutModal";
import MenuContent from "./Components/MenuContent/MenuContent";

const Navbar = () => {
  return (
    <>
      <section id="tool-main-section">
        <nav id="header-section" className="container-layout">
          <div className="tool-logo">
            <img className="logo-img" src={process.env.PUBLIC_URL + "/resources/EZ_Tools_logo.png"}></img>
          </div>
          <MenuContent />
          <LogoutModal />
        </nav>
      </section>
      <Outlet />
    </>
  );
};

export default Navbar;
