export const initialState = {
  count_form_data: {
    data: {
      assignment_id: null,
      source_language: null,
      file_name: null,
      include_counts: null,
    },
    loader: null,
    error: null,
  },

  count_history_data: {
    data: null,
    loader: null,
    error: null,
  },
  curCountPage: null,
  totalCountPage: null,
  count_form_page_access: null,
  loading: null,
  error: null,
  success: null,
};
