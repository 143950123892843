// import wordCountData from "../../../../data/ez_search/word_count_data.json";

export const initialState = {
  word_count_data: {
    data: null,
    loader: null,
    error: null,
  },
  search_phrase_data: [],

  file_download_data: {
    assignment_id: null,
    success: null,
    status: null,
    message: null,
    download_link: null,
    loader: null,
    error: null,
  },

  update_phrases: {
    data: null,
    loader: null,
    error: null,
  },
  curPage: null,
};
