import React, { useRef, useState } from "react";
// import DragnDropUpload from "../../../utils/Components/DragnDropUpload/DragnDropUpload";
import source_lang from "../../../data/ez_count/source_lang/source-languages.json";
import "./CountUpload.scss";
import { ImCancelCircle } from "react-icons/im";
// import { FileContext } from "../../../AppRoutes";
import axios from "axios";
// import { postAuthData } from "../../../services/request";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCountFileData } from "../../../store/features/ezCountSlice/ezCountSlice";
import { toast } from "react-hot-toast";
import DragnDropUpload from "../../../utils/Components/DragnDropUpload/DragnDropUpload";
import { setFileName } from "../../../store/features/utilSlice/utilSlice";
import SelectInputComp from "../../../utils/Components/InputComps/DropdownSelectInput/SelectInputComp";
// import FileUploader from "../../../utils/Components/UploadChunk/UploadChunk";

const CountUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // states used
  const [sourceLang, setSourceLang] = useState(null);
  const { file_info } = useSelector((state) => state.utilData);

  // context used
  // const { files } = useContext(FileContext);
  // const { setCountFileData } = useContext(CountFileInfoContext);

  // Refs used
  const Header = useRef();
  const Footer = useRef();
  const NonWords = useRef();
  const Comments = useRef();
  const Charts = useRef();
  const Notes = useRef();
  const Master = useRef();
  // const sheetName = useRef();

  // to change the state of btn to active
  function activeBtn() {
    document.getElementById("upld-btn").textContent = "Count Words";
    document.getElementById("upld-btn").disabled = false;
    document.getElementById("upld-btn").style.backgroundColor = "#3CC3F2";
  }

  // to change the state of btn when clicked once
  function disableBtn() {
    document.getElementById("upld-btn").textContent = "Counting...";
    document.getElementById("upld-btn").disabled = true;
    document.getElementById("upld-btn").style.backgroundColor = "grey";
  }

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(setFileName(null));
  };

  function handleSubmit(e) {
    e.preventDefault();
    //form inputs validation
    if (!file_info) {
      return toast.error("No files added", { id: "file-check" });
    }
    if (!sourceLang) {
      return toast.error("Please select source language", { id: "file-check" });
    }

    if (file_info[0] && sourceLang) {
      disableBtn();
      let formData = new FormData();
      formData.append("file", file_info[0]);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/ezcount/v1/upload-file/`,
        data: formData,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          if (res.data.success) {
            let data = {
              assignment_id: res.data.data.assignment_id,
              source_language: sourceLang,
              header: Header.current.checked,
              footer: Footer.current.checked,
              non_words: NonWords.current.checked,
              comments: Comments.current.checked,
              charts: Charts.current.checked,
              master: Master.current.checked,
              notes: Notes.current.checked,
              // sheetName: sheetName.current.checked,
            };

            dispatch(getCountFileData(data)).then((res) => {
              if (res.payload.success) {
                navigate("/ez_count_info");
              } else {
                //TODO: btn state to be managed with redux/react
                activeBtn();
                setSourceLang(null);
                toast.error("Unable to Navigate");
              }
            });
          } else {
            toast.error(res.message);
            activeBtn();
            setSourceLang(null);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setTimeout(() => window.location.reload(), 2000);
        });
    }
  }

  return (
    <div className="count-upload-container container-layout">
      <div className="count-upload-section">
        <NavLink className="count-upload-history" to="/ez_count/history">
          HISTORY
        </NavLink>
        <div className="count-upload-form-container">
          <div className="drag-n-drop-upload">
            <DragnDropUpload />
            {file_info && file_info[0] && <ImCancelCircle className="cancel-icon" onClick={handleCancel} />}
          </div>
          <div className="count-upload-form">
            <div className="top-container">
              <div className="dropdown-container">
                <SelectInputComp
                  label={"Source Language"}
                  selectedValue={source_lang?.source_lang?.value}
                  options={source_lang?.source_lang}
                  handleChange={(option) => {
                    setSourceLang(option.value);
                  }}
                  placeholder={"Source"}
                  clearInput={true}
                />
              </div>
              <div className="form-sbmt-btn">
                <button onClick={(e) => handleSubmit(e)} value="Count Words" className="sbmt-btn" id="upld-btn">
                  Count Words
                </button>
              </div>
            </div>

            <div className="checkbox-container">
              <div className="checkbox-container-heading">Include Below for Word Count</div>
              <div className="include_wc_container">
                <div className="include_wc_container_sc">
                  <label htmlFor="header" className="checkbox">
                    <input type="checkbox" id="header" name="header" value="header" ref={Header} defaultChecked={true} />
                    Header
                    <span className="checkmark"></span>
                  </label>

                  <label htmlFor="footer" className="checkbox">
                    <input type="checkbox" id="footer" name="" value="footer" ref={Footer} defaultChecked={true} />
                    Footer
                    <span className="checkmark"></span>
                  </label>

                  <label htmlFor="non_words" className="checkbox">
                    <input type="checkbox" id="non_words" name="" value="non_words" ref={NonWords} defaultChecked={true} />
                    Non Words
                    <span className="checkmark"></span>
                  </label>

                  <label htmlFor="comments" className="checkbox">
                    <input type="checkbox" id="comments" name="" ref={Comments} value="comments" />
                    Comments
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="include_wc_container_sc">
                  {/* <label htmlFor="sheet_name" className="checkbox">
                    <input type="checkbox" id="sheet_name" name="" ref={sheetName} value="sheet_name" defaultChecked={true} />
                    Sheet Name
                    <span className="checkmark"></span>
                  </label> */}
                  <label htmlFor="master" className="checkbox">
                    <input type="checkbox" id="master" name="" ref={Master} value="master" />
                    Master
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="notes" className="checkbox">
                    <input type="checkbox" id="notes" name="" ref={Notes} value="Notes" />
                    Notes ( PPTX Only)
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="charts" className="checkbox">
                    <input type="checkbox" id="charts" name="" ref={Charts} value="charts" />
                    Charts
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountUpload;
