import React from "react";
import HistoryContent from "../HistoryContent/HistoryContent";
import HistoryPagination from "../HistoryPagination/HistoryPagination";

const HistoryInfo = () => {
  return (
    <>
      <HistoryContent />
      <HistoryPagination />
    </>
  );
};

export default HistoryInfo;
