import { useNavigate } from "react-router";
import "./HistoryBtn.scss";
const HistoryBtn = () => {
  const navigate = useNavigate();

  /* below function will Navigate to HistorySection */

  function handleNavigate() {
    navigate("/ez_search/history");
  }

  return (
    <section className="history-btn-section">
      <button className="history-btn" onClick={handleNavigate}>
        History
      </button>
    </section>
  );
};

export default HistoryBtn;
