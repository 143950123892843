import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router";
import { postData } from "../../services/request";
import "./Login.scss";
import { useAuth } from "../../contexts/AuthContext";

const Login = () => {
  const userName = useRef(null);
  const Password = useRef(null);
  const navigate = useNavigate();
  const [showPwd, setShowPwd] = useState(false);
  const { login } = useAuth();

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
      setShowPwd(true);
    } else {
      pswd_input.current.type = "password";
      setShowPwd(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userName.current.value) {
      toast.error("Enter Username");
      return;
    }
    if (!Password.current.value) {
      toast.error("Enter Password");
      return;
    }
    const data = {
      email: userName.current.value,
      password: Password.current.value,
    };

    postData(`${process.env.REACT_APP_API_URL}/api/auth/v1/login/`, data).then((res) => {
      if (res.success === true) {
        localStorage.setItem("token", res.token);
        login();
        localStorage.getItem("token") ? navigate("/ez_count") : navigate("/");
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
    <div className="login-container">
      <div className="login-body-container">
        <div className="login-body-logo-container">
          <img src={process.env.PUBLIC_URL + "/resources/EZ_Tools_logo.png"}></img>
        </div>
        <div className="login-form">
          <div className="login-form-input-container">
            <div className="login-form-input-box">
              <div className="login-form-input-label"> Username / E-mail </div>
              <input type="text" name="user_name" id="user_name" ref={userName} className="login-form-input"></input>
            </div>
            <div className="login-form-input-box">
              <div className="login-form-input-label"> Password </div>
              <div className="login-form-input-password">
                <input type="password" name="password" id="password" ref={Password} className="login-form-input"></input>
                <span
                  className="pswd-show-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    showPassword(Password);
                    Password.current.focus();
                  }}
                >
                  {showPwd ? <FiEye /> : <FiEyeOff />}
                </span>
              </div>
            </div>
          </div>
          <div className="login-form-btn-container">
            <button onClick={handleSubmit} className="login-sbmt-btn" id="login-sbmt-btn">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
